@import "vars";
@import "default";

// Extra large devices (large desktops)
// No media query since the extra-large breakpoint has no upper bound on its width

html {
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

}
body {
  //opacity: 0;
  font-size: 1.6rem;
  line-height: 2.3rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-120px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal_open {
  body {
    //overflow: hidden;
  }
  .wrapper, .main_footer {
    filter: blur(10px);
  }
  .main_content {

  }
  .main_header {

  }
}

.container {
  padding-left: 26rem;
  padding-right: 26rem;
}

.home {
  .container {
    position: relative;
    padding-left: 37rem;
    padding-right: 37rem;
  }
}

.main_header {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-left: 10rem;
  padding-right: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  .header_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header_nav {
    display: flex;
    align-items: center;
  }
  &.stick {
    position: fixed;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: $text-color;
    animation: fadeInDown 0.5s ease forwards;
  }
}
.logo {
  display: flex;
  position: relative;
  color: #fff;
  &:hover, &:focus {
    color: #fff;
    text-decoration: none;
  }
  &__img {
    display: block;
    height: 48.66px;
    width: 56.74px;
  }
  &__title {
    font-size: 8px;
    letter-spacing: 4px;
    line-height: 1;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  &__txt {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 300;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-left: 20px;
    margin-left: 10px;
    border-left: 1px solid #fff;
  }
}
.top_menu {
  font-size: 1.6rem;
  line-height: 1.9rem;
  ul {
    display: flex;
    align-items: center;
  }
  li {
    padding: 0 1rem;
    &.active {
      a {
        color: #fff;
      }
    }
  }
  a {
    display: block;
    padding-bottom: 8px;
    padding-top: 10px;
    color: #fff;
    border-bottom: 2px solid transparent;
    &:hover, &.active {
      color: #fff;
      border-bottom: 2px solid $accent-color;
    }
  }
  .sub_li {
    position: relative;
    &:hover {
      ul {
        opacity: 1;
        pointer-events: auto;
      }
    }
    & > a {
      display: flex;
      align-items: center;
      &:after {
        display: block;
        content: '';
        width: 2.4rem;
        height: 2.4rem;
        background: url(../img/arrow-drop-down.svg) no-repeat center;
        -webkit-background-size: 100%;
        background-size: 100%;
      }
    }
    ul {
      position: absolute;
      padding: 0.5rem 0;
      top: 100%;
      left: 0;
      background-color: #000;
      opacity: 0;
      pointer-events: none;
      transition: all .2s;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
.lang_menu {
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-left: 8rem;
  ul {
    position: relative;
    display: flex;
    &:hover {
      li.unactive {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  li {
    &.active {
      a {
        color: #fff;
        display: flex;
        align-items: center;
        &:after {
          display: block;
          content: '';
          width: 2.4rem;
          height: 2.4rem;
          background: url(../img/arrow-drop-down.svg) no-repeat center;
          -webkit-background-size: 100%;
          background-size: 100%;
        }
      }
    }
    &.unactive {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #000;
      opacity: 0;
      pointer-events: none;
      transition: all .2s;
    }
  }
  a {
    padding: 1rem;
    color: #fff;
    display: flex;
    &:hover, &.active {
      color: #fff;
    }
  }
}

.menu_btn {
  display: none;
}
.call_btn {
  display: none;
}
.menu_mob_wrap {
  display: none;
}

.s_title {
  position: relative;
  font-weight: bold;
  font-size: 4rem;
  line-height: 4.8rem;
  &:before {
    position: absolute;
    display: block;
    content: '';
    width: 3.9rem;
    height: 5.5rem;
    top: -0.4rem;
    left: -1.4rem;
    background: url(../img/stripe-red.svg) no-repeat top left;
    -webkit-background-size: contain;
    background-size: contain;
    z-index: 0;
  }
  span {
    position: relative;
    z-index: 10;
  }
}

.wrapper {
  overflow: hidden;
}

.home_top {
  //overflow: hidden;
  position: relative;
  height: 100vh;
  background: #1F130A;
  .top_ctn {
    padding-top: 35rem;
    padding-left: 20%;
    padding-right: 20%;
    position: relative;
    z-index: 10;
    text-align: center;
    color: #FFFFFF;
  }
  .t1 {
    margin-bottom: 2rem;
    font-weight: 700;
    font-size: 7.5rem;
    line-height: 9rem;
    text-transform: uppercase;
  }
  .t2 {
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 5rem;
    line-height: 6rem;
    text-transform: uppercase;
  }
  .t3 {
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 4rem;
    line-height: 4.8rem;
    text-transform: uppercase;
  }
  .buttons {
    margin-left: auto;
    margin-right: auto;
    margin-top: 13.5rem;
  }
  .bg_trgl {
    position: absolute;
    left: 50%;
    bottom: 1.1rem;
    margin-left: -37rem;
    width: 74rem;
  }
  .top_ctn_inner {
    position: relative;
    z-index: 10;
  }
  .top_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      height: 100%;
      min-width: 100%;
      max-width: none;
    }
  }
  .mouse {
    position: absolute;
    bottom: 7rem;
    left: 50%;
    margin-left: -11px;
  }
}

.page_top {
  //overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100%;
  background: #1F130A;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .top_ctn {
    padding-top: 7.5rem;
    padding-left: 20%;
    padding-right: 20%;
    position: relative;
    z-index: 10;
    height: 75.5%;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .top_links {
    height: 24.5%;
    width: 100%;
    background: #1F130A;
    display: flex;
    align-items: center;
  }
  .top_links_inner {
    padding: 0 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .top_links_item {
    display: flex;
    align-items: center;
    .divider {
      margin-right: 3.5rem;
      margin-left: 3.5rem;
      width: 1px;
      height: 10rem;
      background-color: #6F5641;
    }
  }
  .top_links_item_icon {
    flex-shrink: 0;
  }
  .t1 {
    margin-bottom: 2rem;
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.8rem;
    text-transform: uppercase;
  }
  .t2 {
    margin-bottom: 2rem;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.4rem;
    text-transform: uppercase;
  }
  .bg_trgl {
    position: absolute;
    left: 50%;
    bottom: 1.1rem;
    margin-left: -37rem;
    width: 74rem;
  }
  .top_ctn_inner {
    position: relative;
    z-index: 10;
    font-size: 2rem;
    line-height: 2.4rem;
    .buttons {
      margin-left: auto;
      margin-right: auto;
      margin-top: 9rem;
    }
  }
  .top_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      min-height: 100%;
      min-width: 100%;
      max-width: none;
    }
  }
}


.page_top_ban {
  position: relative;
  height: 42.2rem;
  width: 100%;
  background: #1F130A;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  h1, .t1 {
    margin-bottom: 2rem;
    font-weight: 700;
    font-size: 4rem;
    line-height: 1.2;
    text-transform: uppercase;
  }
  h2, .t2 {
    font-weight: 700;
    font-size: 4rem;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .top_ctn {
    padding-top: 5rem;
    padding-left: 20%;
    padding-right: 20%;
    position: relative;
    z-index: 10;
    height: 100%;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .top_ctn_inner {
    position: relative;
    z-index: 10;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .top_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      height: 100%;
      min-width: 100%;
      max-width: none;
    }
  }
}

.page_num {
  font-family: 'Squares', sans-serif;
  font-weight: 700;
  font-size: 14.4rem;
  line-height: 16.6rem;
  position: absolute;
  top: -10rem;
  &:before {
    position: absolute;
    display: block;
    content: '';
    width: 2px;
    height: 20rem;
    left: 50%;
    margin-left: -1px;
    top: -16rem;
    background: #C7352B;
  }
  &.black {
    margin-right: 2.5rem;
    right: 100%;
    color: #1F130A;
  }
  &.white {
    margin-left: 2.5rem;
    left: 100%;
    color: #fff;
  }
  span {
    position: relative;
    z-index: 10;
  }
}

.credits_lawyer {
  padding-top: 16rem;
  padding-bottom: 15rem;
  color: $text-color;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.8rem;
  .credits_lawyer_wrap {
    display: flex;
    align-items: flex-start;
    position: relative;
  }
  .s_title {
    margin-bottom: 5rem;
  }
  .btns_wrap {
    margin-top: 10rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .buttons {
    margin-right: 2.8rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
.credits_lawyer_ctn {
  padding-left: 9rem;
  padding-top: 1.6rem;
  text-transform: uppercase;
}
.credits_lawyer_image {
  position: relative;
  width: 56.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .img_wrap {
    overflow: hidden;
    background: $text-color;
    img {
      display: block;
      opacity: .4;
    }
  }
}

.block_frame {
  position: absolute;
  top: -1.1rem;
  left: 1.1rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    flex-shrink: 0;
  }
  &.red {
    border: 1px solid #C7352B;
  }
  &.grey {
    border: 1px solid #A8A9AA;
  }
}

section {
}

.problem_solutions {
  padding-top: 19.4rem;
  padding-bottom: 10.5rem;
  color: #fff;
  background: url(../img/bg-img-4.jpg) top right;
  -webkit-background-size: cover;
  background-size: cover;
  .page_num {
    top: -14rem;
  }
  .s_title {
    margin-bottom: 8rem;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
  }
  .problem_solutions_wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.problem_solutions_inner {
  margin-bottom: 7.5rem;
  position: relative;
  display: flex;
  width: calc(100% + 11rem);
  margin-left: -5.5rem;
  margin-right: -5.5rem;
}
.problem_solutions_item {
  position: relative;
  padding: 7.5rem 4.4rem 6rem 9.5rem;
  margin-left: 5.5rem;
  margin-right: 5.5rem;
  width: 50%;
  height: 30.5rem;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 3.5rem;
  text-transform: uppercase;
  border: 1px solid #E0675E;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  .text {
    position: relative;
    span {
      position: absolute;
      top: 50%;
      left: -3.5rem;
      margin-top: -11rem;
      font-family: 'Adelle Cyrillic', sans-serif;
      font-size: 18rem;
      line-height: 22.5rem;
      font-weight: bold;
      color: #C7352B;
      opacity: 0.5;
    }
    p {
      position: relative;
      z-index: 10;
    }
  }
}

.credit_type {
  padding-top: 16rem;
  padding-bottom: 15rem;
  color: $text-color;
  .credit_type_wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .container {
    padding-right: 27rem;
  }
  .s_title {
    margin-bottom: 5rem;
  }
}
.credit_type_image {
  position: relative;
  width: 36.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .img_wrap {
    overflow: hidden;
    background: $text-color;
    img {
      display: block;
      opacity: .4;
    }
  }
}
.credit_type_ctn {
  padding-right: 9rem;
  padding-top: 3.6rem;
}
.credit_type_items {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-top: 4rem;
    margin-bottom: 4.5rem;
    position: relative;
    width: 50%;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 2.6rem;
  }
  .item_text {
    position: relative;
    margin-bottom: 2.4rem;
  }
  .buttons {
    position: relative;
    z-index: 10;
    margin-left: 3.5rem;
  }
}
.list_styled {
  .num {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -8rem;
    font-family: 'Adelle Cyrillic', sans-serif;
    font-weight: bold;
    font-size: 14rem;
    line-height: 17.5rem;
    color: #E8E8E8;
  }
  .text, p {
    position: relative;
    z-index: 10;
    padding-left: 3.5rem;
  }
}

.what_to_do {
  position: relative;
  padding-top: 19.4rem;
  padding-bottom: 10.5rem;
  color: #fff;
  .page_num {
    top: -7rem;
    &:before {
      height: 23rem;
    }
  }
  .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #1F130A;
    clip-path: polygon(0 0, 100% 9%, 100% 100%, 0% 100%);
  }
  .s_title {
    margin-bottom: 11.5rem;
  }
  .container {
    position: relative;
    z-index: 10;
  }
  .what_to_do_wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.what_to_do_inner {
  margin-bottom: 7.5rem;
  position: relative;
  display: flex;
  width: calc(100% + 10rem);
  margin-left: -5rem;
  margin-right: -5rem;
}
.what_to_do_item {
  position: relative;
  margin-left: 5rem;
  margin-right: 5rem;
  width: 50%;
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 3.8rem;
  text-transform: uppercase;
  img {
    margin-bottom: 4rem;
  }
  .buttons {
    margin-top: 5rem;
  }
  .item_ctn {
    padding: 4.3rem 4rem 6rem;
    background: #C7352B;
    height: 35rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .block_frame {
    z-index: 10;
    border: 1px solid #E0675E;
    pointer-events: none;
  }
}

.debtor_protect {
  padding-top: 16rem;
  padding-bottom: 15rem;
  color: $text-color;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  .debtor_protect_wrap {
    position: relative;
    display: flex;
    align-items: flex-start;
  }
  .s_title {
    margin-bottom: 6rem;
  }
  .btns_wrap {
    margin-top: 10rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .buttons {
    margin-right: 2.8rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
.debtor_protect_image {
  position: relative;
  width: 33.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .img_wrap {
    overflow: hidden;
    background: $text-color;
    img {
      display: block;
      opacity: .4;
    }
  }
}
.debtor_protect_ctn {
  padding-left: 9rem;
}
.debtor_protect_items {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-top: 6rem;
    margin-bottom: 6rem;
    position: relative;
    width: 50%;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.4rem;
    color: $text-color;
    display: flex;
    align-items: center;
    .num {
      margin-top: -8.6rem;
    }
  }
  .item_text {
    position: relative;
  }
  .buttons {
    position: relative;
    z-index: 10;
  }
}

.registers {
  position: relative;
  padding-top: 16rem;
  padding-bottom: 10rem;
  color: #fff;
  .page_num {
    top: -8rem;
  }
  .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #1F130A;
    clip-path: polygon(0 9%, 100% 0, 100% 100%, 0% 100%);
  }
  .s_title {
    font-size: 3.5rem;
    line-height: 4.2rem;
  }
  .registers_wrap {
    position: relative;
  }
}
.registers_inner {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: calc(100% + 19rem);
  margin-left: -9.5rem;
  margin-right: -9.5rem;
}
.registers_item {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .item_header {
    margin-bottom: 5.3rem;
    padding-left: 7.5rem;
    height: 8.4rem;
    align-self: flex-start;
    display: flex;
    align-items: center;
  }
  .item_image {
    margin-bottom: 5rem;
    img {
      display: block;
    }
  }
}

.arrests_cancel {
  padding-top: 12.4rem;
  padding-bottom: 6.5rem;
  background: #fff;
  .page_num {
    top: -7rem;
  }
  .s_title {
    margin-bottom: 11.5rem;
  }
  .arrests_cancel_wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.arrests_cancel_inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 10rem);
  margin-left: -5rem;
  margin-right: -5rem;
}
.arrests_cancel_item {
  position: relative;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 5rem;
  width: calc(50% - 10rem);
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.3rem;
  text-transform: uppercase;
  color: #fff;
  .btns_wrap {
    margin-top: 5rem;
    display: flex;
    align-items: center;
  }
  .buttons {
    margin-right: 3rem;
    &:last-child {
      margin-right: 0;
    }
  }
  .item_img {
    background: #1F130A;
    img {
      display: block;
      opacity: .3;
      width: 100%;
    }
  }
  .item_ctn {
    position: absolute;
    padding: 6rem 8rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .block_frame {
    z-index: 10;
    border: 1px solid #E0675E;
    pointer-events: none;
  }
}

.bankruptcy {
  padding-top: 13rem;
  padding-bottom: 13rem;
  color: #fff;
  background: url(../img/bg-img-5.jpg) top right;
  -webkit-background-size: cover;
  background-size: cover;
  //clip-path: polygon(0 0, 100% 9%, 100% 100%, 0% 100%);
  .s_title {
    margin-bottom: 5rem;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
  }
  .bankruptcy_wrap {
    position: relative;
    display: flex;
    align-items: center;
  }
  .text-1 {
    margin-bottom: 5.5rem;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 3.5rem;
    text-transform: uppercase;
  }
  .text-2 {
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 3.5rem;
    color: #E5E5E5;
  }
  .triangle {
    position: relative;
    margin-left: -6rem;
    width: 53.3rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page_num {
    top: -1rem;
  }
}
.bankruptcy_inner {
  padding-top: 5rem;
}

.loan_limitation {
  padding-top: 16rem;
  padding-bottom: 15rem;
  color: $text-color;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  .loan_limitation_wrap {
    position: relative;
    display: flex;
    align-items: flex-start;
  }
  .s_title {
    margin-bottom: 6rem;
    margin-left: 1.4rem;
  }
  .btns_wrap {
    margin-top: 10rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .buttons {
    margin-right: 2.8rem;
    &:last-child {
      margin-right: 0;
    }
  }
  .page_num {
    margin-right: 2.5rem;
  }
}
.loan_limitation_image {
  position: relative;
  width: 33.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .img_wrap {
    overflow: hidden;
    background: $text-color;
    img {
      display: block;
      opacity: .4;
    }
  }
}
.loan_limitation_ctn {
  padding-top: 4rem;
  padding-left: 7rem;
}
.loan_limitation_items {
  display: flex;
  flex-wrap: wrap;
  .item {
    padding-right: 1rem;
    margin-top: 6rem;
    margin-bottom: 6rem;
    position: relative;
    width: 50%;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2rem;
    color: $text-color;
    display: flex;
    align-items: center;
    .num {
      margin-top: -8.6rem;
    }
    .text , p {
      padding-left: 2.5rem;
    }
  }
  .item_text {
    position: relative;
  }
  .buttons {
    position: relative;
    z-index: 10;
  }
}

.reasons {
  padding-top: 15rem;
  padding-bottom: 14.5rem;
  color: $text-color;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  .container {
    display: flex;
    align-items: flex-start;
  }
  .s_title {
    margin-bottom: 6rem;
  }
  .btns_wrap {
    margin-top: 10rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .buttons {
    margin-right: 2.8rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
.reasons_image {
  position: relative;
  width: 33.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .img_wrap {
    overflow: hidden;
    background: $text-color;
    img {
      display: block;
      opacity: .4;
    }
  }
}
.reasons_ctn {
  padding-left: 9rem;
}
.reasons_items {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-top: 6rem;
    margin-bottom: 6rem;
    position: relative;
    width: 33.3333%;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $text-color;
    display: flex;
    align-items: center;
    .num {
      margin-top: -8.6rem;
    }
    p:first-child {
      padding-left: 0;
    }
  }
  .item_text {
    position: relative;
  }
  .buttons {
    position: relative;
    z-index: 10;
  }
}

.check_data {
  padding-top: 13rem;
  padding-bottom: 13rem;
  color: $text-color;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  background: #EFEFEF;
  .container {
    display: flex;
    justify-content: space-between;
  }
  .s_title {
    margin-bottom: 5rem;
  }
  .btns_wrap {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .buttons {
    margin-bottom: 3.4rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.check_data_ctn {
  padding-right: 5rem;
  padding-top: 6rem;
  text-transform: uppercase;
}
.check_data_image {
  position: relative;
  max-width: 65.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .img_wrap {
    overflow: hidden;
    filter: drop-shadow(0px 4px 45px rgba(0, 0, 0, 0.14));
    img {
      display: block;
    }
  }
}

.how_it_work {
  padding-top: 15rem;
  padding-bottom: 15rem;
  color: #fff;
  font-size: 2rem;
  line-height: 2.4rem;
  background: url(../img/bg-img-6.jpg) top right;
  -webkit-background-size: cover;
  background-size: cover;
  .s_title {
    margin-bottom: 2.3rem;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
  }
  .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
.how_it_work_items {
  margin-top: 10.5rem;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 3.5rem;
  text-transform: uppercase;
  li {
    position: relative;
    padding-left: 3.5rem;
    margin-bottom: 10.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  span {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -11rem;
    font-family: 'Adelle Cyrillic', sans-serif;
    font-size: 18rem;
    line-height: 22.5rem;
    font-weight: bold;
    color: #C7352B;
    opacity: 0.5;
  }
  p {
    position: relative;
    z-index: 10;
  }
}

.what_you_get {
  padding-top: 15rem;
  padding-bottom: 12rem;
  .container {
    display: flex;
    align-items: center;
  }
  .s_title {
    margin-bottom: 3rem;
  }
}
.what_you_get_image {
  position: relative;
  width: 33.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .img_wrap {
    overflow: hidden;
    background: $text-color;
    img {
      display: block;
      opacity: .4;
    }
  }
}
.what_you_get_ctn {
  padding-right: 5rem;
}
.what_you_get_items {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;
    position: relative;
    width: 33.3333%;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.4rem;
    color: $text-color;
  }
  .item_text {
    position: relative;
    margin-bottom: 2.4rem;
  }
}

.prices {
  padding-top: 10.5rem;
  padding-bottom: 16rem;
  color: #fff;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
  background: #1F130A;
  .s_title {
    margin-bottom: 10rem;
  }
}
.prices_inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.prices_banner {
  position: relative;
  width: 52.4rem;
  text-align: center;
  .buttons {
    margin-top: 5rem;
  }
  .t1 {
    margin-bottom: 1.5rem;
    font-weight: bold;
    font-size: 3rem;
    line-height: 3.6rem;
  }
  .item_ctn {
    padding: 6rem 4rem 6rem;
    background: #C7352B;
    //height: 29rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .block_frame {
    z-index: 10;
    border: 1px solid #E0675E;
    pointer-events: none;
  }
}
.price_list {
  //.text {
  //  &:before {
  //    content: '- ';
  //  }
  //}
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .price {
    padding-left: 4.5rem;
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 2.6rem;
  }
  .sub_title {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 700;
  }
}

.our_publications {
  padding-top: 10.5rem;
  padding-bottom: 10rem;
  .s_title {
    margin-bottom: 10.5rem;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.our_publications_inner {
  position: relative;
  padding-bottom: 4rem;
  margin-left: -3rem;
  margin-right: -3rem;
  width: calc(100% + 6rem);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.our_publications_item {
  margin-bottom: 6rem;
  margin-left: 3rem;
  margin-right: 3rem;
  position: relative;
  width: calc(33.3333% - 6rem);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .img_wrap {
    overflow: hidden;
    background: $text-color;
    img {
      display: block;
      opacity: .4;
    }
  }
  .text {
    position: absolute;
    padding: 3rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-weight: 600;
    font-size: 2rem;
    line-height: 3.5rem;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .block_frame {
    top: auto;
    bottom: -1rem;
    right: -1rem;
  }
}

.s_video {
  padding-top: 15rem;
  padding-bottom: 15rem;
  background-color: #fff;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
  .s_title {
    margin-bottom: 5rem;
  }
  .buttons {
    margin-top: 5rem;
  }
  .container {
    display: flex;
    align-items: center;
  }
  .video_frame {
    width: 63rem;
    height: 35.5rem;
  }
}
.video_frame{
  overflow:hidden;
  position:relative;
  iframe {
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
  }
}
.s_video_inner {
  padding-left: 10rem;
  padding-bottom: 2rem;
}

.loans_escape {
  padding-top: 13rem;
  padding-bottom: 13rem;
  color: $text-color;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  .container {
    display: flex;
    align-items: flex-start;
  }
  .s_title {
    margin-bottom: 0;
  }
  .btns_wrap {
    margin-top: 10rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .buttons {
    margin-right: 2.8rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
.loans_escape_image {
  position: relative;
  width: 33.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .img_wrap {
    height: 53rem;
    overflow: hidden;
    background: $text-color;
    img {
      display: block;
      opacity: .4;
    }
  }
}
.loans_escape_ctn {
  padding-left: 9rem;
}
.loans_escape_items {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-top: 6rem;
    margin-bottom: 5.5rem;
    position: relative;
    width: 33.3333%;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.4rem;
    color: $text-color;
    display: flex;
    align-items: center;
    .num {
      margin-top: -8.6rem;
    }
    p:first-child {
      padding-left: 0;
    }
  }
  .item_text {
    position: relative;
  }
  .buttons {
    position: relative;
    z-index: 10;
  }
}

.pic_banner {
  padding-top: 16rem;
  padding-bottom: 14rem;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.pic_banner_left {
  max-width: 50rem;
  .pic_logo {
    margin-bottom: 5rem;
  }
  .t1 {
    margin-bottom: 5.5rem;
    font-size: 2.4rem;
    line-height: 2.9rem;
  }
  .t2 {
    width: 22.5rem;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #C7352B;
    text-transform: uppercase;
    margin-right: 3rem;
  }
  .text_arrow {
    display: flex;
    align-items: center;
  }
}
.pic_banner_right {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .item {
    position: relative;
    margin-bottom: 2rem;
    padding: 2.7rem 3.7rem;
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 2.6rem;
    text-transform: uppercase;
    border: 1px solid #C7352B;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 50%;
      left: -1px;
      margin-top: -2.5rem;
      height: 5rem;
      width: 2px;
      background-color: #fff;
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      top: 50%;
      right: -1px;
      margin-top: -2.5rem;
      height: 5rem;
      width: 2px;
      background-color: #fff;
    }
    p {
      padding-right: 3rem;
    }
  }
}

.pic_banner_2 {
  margin-top: 70px;
  display: none;
  text-align: center;
  .pic_banner_top {
    padding: 40px 30px 45px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    background: #C7352B;
    border: 1px solid #C7352B;
  }
  .t1 {
    margin-bottom: 36px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .pic_banner_bottom {
    border: 1px solid #C7352B;
    padding: 20px 30px 25px;
    color: #C7352B;
    font-size: 20px;
    line-height: 24px;
  }
  address {
    font-style: normal;
  }
  .address {
    margin-bottom: 35px;
  }
  .phones {
    a {
      margin-bottom: 25px;
      display: block;
      &:hover {
        color: #fff;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.microcredit_banner {
  padding-top: 17rem;
  padding-bottom: 12.5rem;
  background: #1F130A;
  position: relative;
  color: #fff;
  .s_title {
    margin-bottom: 2.5rem;
  }
  .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .item {
    width: 40%;
  }
  .trgls {
    position: absolute;
  }
  .trgl-1 {
    left: 50%;
    top: 50%;
    margin-top: -16rem;
    margin-left: -18rem;
    width: 35.5rem;
  }
  .trgl-2 {
    left: 9rem;
    bottom: 8rem;
    width: 7.5rem;
    transform: rotateZ(30deg);
    filter: blur(5px)
  }
  .trgl-3 {
    right: 9rem;
    top: 9rem;
    width: 11rem;
    transform: rotateZ(-30deg);
    filter: blur(5px)
  }
}

.statements_complaints {
  padding-top: 10rem;
  padding-bottom: 13rem;
  .container {
    display: flex;
    align-items: center;
  }
  .image {
    margin-right: 5rem;
    flex-shrink: 0;
  }
  .s_title {
    margin-bottom: 7rem;
  }
  .btns_wrap {
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .buttons {
    margin-right: 3rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.s_question_answer {
  padding-top: 10rem;
  padding-bottom: 10rem;
  background: #EFEFEF;
  .container {
    display: flex;
    justify-content: space-between;
  }
  .left {
    .s_title {
      margin-bottom: 7rem;
    }
  }
  .right {
    .s_title {
      margin-bottom: 10rem;
    }
  }
}
.question_answer {
  width: 65rem;
  height: 51rem;
  padding-right: 5rem;
  overflow-y: auto;
  .item {
    margin-bottom: 4rem;
  }
  .question {
    display: flex;
    align-items: center;
    &.active {
      .question_icon {
        background: #C7352B url(../img/cross-white.svg) no-repeat center;
      }
      .question_text {
        color: #C7352B;
      }
    }
  }
  .question_icon {
    margin-right: 2rem;
    font-weight: 500;
    font-size: 2.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    color: #1F130A;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff url(../img/plus-black.svg) no-repeat center;
  }
  .question_text {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .answer {
    padding-top: 1rem;
    padding-left: 5rem;
    font-size: 1.6rem;
    line-height: 3rem;
    display: none;
  }
}

.ask_form {
  width: 50rem;
  .form_title {
    margin-bottom: 2.5rem;
    font-size: 2.5rem;
    line-height: 3rem;
    color: #5A5A5A;
  }
  .form_field {
    margin-bottom: 3rem;
  }
  input {
    color: #504137;
    font-size: 14px;
    line-height: 17px;
    &::placeholder {
      color: #504137;
    }
  }
  textarea {
    height: 16rem;
    font-size: 14px;
    line-height: 17px;
    &::placeholder {
      color: #504137;
    }
  }
}
.form_field {
  input, textarea {
    padding: 1.2rem 1.8rem;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    resize: vertical;
  }
}

.s_faqs {
  padding-top: 10rem;
  padding-bottom: 10rem;
  .s_title {
    margin-bottom: 6rem;
  }
}
.faqs_list {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
  li {
    border-bottom: 1px solid #CFCFCF;
  }
  a {
    padding: 3rem;
    position: relative;
    display: flex;
    align-items: center;
    &:hover, &:focus {
      color: #C7352B;
    }
    &:before {
      margin-right: 2rem;
      display: block;
      content: '';
      width: 2.8rem;
      height: 2.8rem;
      background: url(../img/newspaper.svg) no-repeat center;
      -webkit-background-size: contain;
      background-size: contain;
    }
  }
}

.page_form {
  padding: 6rem 5rem;
  width: 43rem;
  background: rgba(#6F5641, 0.68);
  box-shadow: 6px 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 2rem;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form_title {
    margin-bottom: 50px;
    text-align: center;
    font-size: 2.5rem;
    line-height: 3.5rem;
    text-transform: uppercase;
    color: #DFC1A8;
  }
  .checks_inline {
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .form_field {
    width: 100%;
    input {
      margin-bottom: 2.5rem;
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
      height: 4rem;
      font-size: 14px;
      line-height: 4rem;
      background: #FFFFFF;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
  .submit {
    margin-top: 3.5rem;
  }
}

.main_footer {
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #fff;
  address {
    margin-bottom: 2.7rem;
    font-style: normal;
  }
  .email {
    margin-bottom: 3rem;
    font-weight: 400;
    color: #3DADFF;
    a:hover {
      text-decoration: underline;
    }
  }
  .phones {
    display: flex;
    a {
      margin-right: 2.3rem;
    }
  }
  .links {
    a {
      display: block;
      margin-bottom: 3rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .map {
    height: 68rem;
  }
}
.main_footer_top {
  padding: 10rem 26rem;
  width: 100%;
  background: #1F130A;
  display: flex;
  justify-content: space-between;
}
.footer_socials {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  a {
    margin-right: 3rem;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-child {
      margin-right: 0;
    }
  }
}

.s_socials {
  padding-top: 10rem;
  padding-bottom: 10rem;
  background: #1F130A;
  color: #fff;
  .s_title {
    margin-bottom: 7.5rem;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.socials {
  display: flex;
  align-items: center;
  a {
    margin-right: 6rem;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      //opacity: 0.8;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.page_services {
  .s_video {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
.how_pay_services {
  padding-top: 14.5rem;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .s_title {
    margin-bottom: 9.5rem;
  }
  .inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.services_block {
  padding: 10rem 11.5rem;
  background: #EFEFEF;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
  &:nth-child(2n) {
    background: transparent;
  }
  .s_title {
    margin-left: 2rem;
    margin-bottom: 6rem;
  }
}
.services_list {
  &.no_border {
    li {
      border-bottom-color: transparent;
    }
  }
  li {
    padding: 2rem 1.5rem;
    border-bottom: 1px solid #A8A9AA;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .text {
    width: 75%;
  }
  .price {
    width: 25%;
    flex-shrink: 0;
    font-weight: 700;
    font-size: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .accent {
    margin-left: 2rem;
    font-size: 14px;
    line-height: 17px;
  }
  .pay_btn, .accent {
    &:hover {
      text-decoration: underline;
    }
  }
}

.page_contacts_inner {
  padding-top: 10.5rem;
  padding-bottom: 16rem;
  background: #EFEFEF;
  .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .left {
    .s_title {
      margin-bottom: 14rem;
    }
  }
  .right {
    .s_title {
      margin-bottom: 6rem;
    }
  }
  .socials {
    a {
      margin-right: 3.4rem;
      width: 3.4rem;
      height: 3.4rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .address {
    margin-bottom: 5rem;
  }
  address {
    font-style: normal;
  }
  .email {
    margin-bottom: 6rem;
    font-weight: 400;
    color: #3DADFF;
    a:hover {
      text-decoration: underline;
    }
  }
  .phones {
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
  }
  .contacts_item {
    position: relative;
    padding-left: 5rem;
  }
  .contacts_item_icon {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.page_articles_inner {
  padding-top: 5rem;
}

.page_article_inner {
  width: 83rem;
  margin-left: auto;
  margin-right: auto;
}
.article_wrap {
  padding-top: 5rem;
  padding-bottom: 10rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.8rem;
  text-align: justify;
  color: #1F130A;
  .image {
    margin-bottom: 3rem;
  }
  a {
    color: $accent-color;
    text-decoration: underline;
    &:hover, &:focus {
      color: $accent-color;
      text-decoration: none;
    }
  }
  .more_link {
    margin-top: 2rem;
    text-transform: uppercase;
  }
  .s_title {
    margin-top: 6rem;
    margin-bottom: 4rem;
    text-align: left;
  }
  ul {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .red_banner {
    margin-top: 5rem;
    margin-bottom: 3rem;
  }
  .quotted {
    position: relative;
    margin-top: 5rem;
    padding: 4rem 12rem;
    font-style: italic;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.8rem;
    border: 1px solid $text-color;
  }
  .quote {
    padding: 1.5rem;
    position: absolute;
    background-color: #fff;
    &.left {
      top: -1.6rem;
      left: -1.5rem;
    }
    &.right {
      bottom: -2.2rem;
      right: -1.5rem;
    }
  }
}
.page_article_top {
  img {
    width: 100%;
  }
  .caption {
    margin-bottom: 2rem;
  }
}
.red_banner {
  position: relative;
  width: 100%;
  text-align: left;
  color: #fff;
  .t1 {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 3rem;
    line-height: 3.6rem;
  }
  .t2 {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .item_ctn {
    padding: 4rem 7.5rem 5rem;
    background: #C7352B;
  }
  .block_frame {
    z-index: 10;
    border: 1px solid #E0675E;
    pointer-events: none;
  }
}
.top_articles {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.comments-area {
  width: 65rem;
  height: 51rem;
  padding-right: 5rem;
  overflow-y: auto;
  .children {
    padding-left: 62px;
  }
}
.comment-wrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.comment-img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  overflow: hidden;
  background: #fff url(../img/anonim-user.svg) no-repeat center;
  -webkit-background-size: 50%;
  background-size: 50%;
}
.comment-body {
  padding-bottom: 15px;
  padding-top: 10px;
  padding-left: 16px;
  position: relative;
  width: 100%;
  font-size: 16px;
  line-height: 23px;
}
.comment-author {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
.comment-date {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 143.34%;
}
.comment-reply {
  padding-left: 9.5px;
  padding-right: 9.5px;
  position: absolute;
  top: 0;
  right: 0;
  height: 24px;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid #cecbc9;
  background: #fff;
  color: $text-color;
  text-transform: uppercase;
  transition: all .2s;
  border-radius: 10em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover, &:focus {
    background: #cecbc9;
    a {
      color: $text-color;
    }
  }
}

.map {
  position: relative;
  height: 680px;
  width: 100%;
  &:after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url("../img/map-with-marker.png") no-repeat center;
    -webkit-background-size: cover;
    background-size: cover;
  }
  a {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
  }
}
.map_2 {
  width: 100%;
  height: 65rem;
  iframe {
    display: block;
    width: 100%;
    height: 65rem;
  }
}

.mfp-bg {
  opacity: .4;
  background: #000;
}
.mfp-wrap {
  top: 0 !important;
  position: fixed !important;
}
.mfp-container {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items:center;
  justify-content:center;
}
.mfp-content {
  position: relative;
  margin-top: 0;
  max-height: 100%;
  width: auto!important;
  .ask-question {
    display: block;
  }
}
.mfp-close {
  position: absolute;
  right: -45px;
  top: -13px;
  opacity: 1;
  transform: rotateZ(0deg);
  transition: transform .5s;
  &:hover {
    transform: rotateZ(360deg);
  }
}
.mfp-close:active {
  top: -13px;
}
button.mfp-close {
  font-size: 0;
  background: transparent url(../img/close-white.svg) no-repeat center;
  background-size: 18px;
}
.ask-question {
  display: none;
}

.popup,
.popup_anket {
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  background: rgba(0, 0, 0, .4);
  z-index: 40
}
.ask-question {
  padding: 35px;
  background: #fff;
  box-shadow: 0 0 10px #000;
  position: relative;
  width: 100%;
  max-width: 440px
}
.ask-question-heading {
  font-size: 25px;
  line-height: 1.2;
  font-weight: 400;
  padding-left: 20px;
  border-left: 2px solid #ca3931;
  margin-bottom: 35px
}
.ask-question form {
  display: flex;
  flex-direction: column
}
.ask-question form input,
.ask-question form textarea {
  font-family: SF-Pro-Displaty-Thin;
  font-size: 14px;
  padding: 15px;
  resize: none;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  outline: 0;
  width: 100%
}
.ask-question form input:focus,
.ask-question form textarea:focus {
  border: 1px solid #ca3931
}
.ask-question form textarea {
  height: 120px;
  margin-bottom: 10px
}
.ask-question form .buttons {
  position: relative;
  top: -1px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  width: auto;
  height: 3.8rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 400;
  border: none;
  border-radius: 10em;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background: #C7352B;
  color: #fff;
  transition: all .2s;
  box-shadow: 0px 2px 0px #75160F;
  &:active {
    top: 1px;
    color: #fff;
    background: darken(#C7352B, 2);
    box-shadow: 0px 0px 0px #75160F;
    transition: all .2s;
    border: none;
    height: 3.8rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 400;
  }
  &:hover {
    color: #fff;
    transition: all .2s;
    height: 3.8rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 400;
    cursor: pointer;
  }
  &:focus {
    color: #fff;
    border: none;
    height: 3.8rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 400;
  }
}
.wpcf7 p {
  text-align: center
}
.ask-question-footer {
  color: #828282;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}
.ask-question hr {
  width: 100%;
  height: 2px;
  margin: 0;
}


// ======---MAIN-----========================================================

.page_top_w-adv {
  & .top_ctn {
    text-align: left;
    justify-content: flex-start;
  }
  & .top_ctn_inner {
    width: 100%;
  }
  & .top_bg:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1F130A;
    opacity: 0.4;
  }
  & .title-1{
    font-size: 8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 9.5rem;
    margin-bottom: 1rem;
  }
  & .title-2{
    font-size: 6rem;
    line-height: 7.2rem;
    &>.larger {
      font-size: 7rem;
      line-height: 8.4rem;
    }
  }
  & .top_ctn_inner .buttons {
    margin-top: 3.5rem;
  }

}

.page-top-advantages {
  display: flex;
  flex-wrap: wrap;
  max-width: 70%;
  margin-top: 5rem;
  padding-left: 1.9rem;
  &__item {
    position: relative;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 5.5rem;
    margin-right: 5rem;
    margin-bottom: 2.5rem;
    max-width: 50%;
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 3.9rem;
      height: 5.5rem;
      top: 0;
      left: -1.9rem;
      background: url(../img/stripe-red.svg) no-repeat top left;
      -webkit-background-size: contain;
      background-size: contain;
      z-index: -1;
    }
  }
}

.img_wrap {
  width: 100%;
}

.what-if {
  padding-top: 16rem;
  padding-bottom: 15rem;
  color: $text-color;
  .what-if_wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .container {
    padding-left: 26rem;
    padding-right:8rem;
  }
  .s_title {
    margin-bottom: 2rem;
  }
  .sub_title {
    margin-bottom: 5rem;
    font-size: 2.5rem;
    line-height: 3rem;
    color: #646464;
  }
}
.what-if_image {
  position: relative;
  width: 34.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .img_wrap {
    overflow: hidden;
    background: $text-color;
    img {
      display: block;
      opacity: .4;
    }
  }
}
.what-if_ctn {
  padding-left: 9rem;
  width: 100%;
}
.what-if_items {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-top: 4rem;
    margin-bottom: 4.5rem;
    padding-right: 2rem;
    position: relative;
    width: 33.3333333%;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .item_text {
    position: relative;
    margin-bottom: 2.4rem;
  }
  .buttons {
    position: relative;
    z-index: 10;
    margin-left: 3.5rem;
  }
}
.list_styled {
  .num {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -8rem;
    font-family: 'Adelle Cyrillic', sans-serif;
    font-weight: bold;
    font-size: 14rem;
    line-height: 17.5rem;
    color: #E8E8E8;
  }
  .text, p {
    position: relative;
    z-index: 10;
    padding-left: 3.5rem;
  }
}
.what-if-end {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  &__item-1 {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-right: 9rem;
    max-width: 45rem;
  }
}


.how-it-works {
  position: relative;
  padding-top: 13rem;
  padding-bottom: 15rem;
  color: #fff;
  background: url(../img/how-it-works-bg.png) center;
  background-size: cover;
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #1F130A;
  }
  .s_title {
    margin-bottom: 2rem;
  }
  .sub_title {
    margin-bottom: 9rem;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .how-it-works__left,.how-it-works__right {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
  }
  .container {
    display: flex;
    // padding: 0 28rem;
  }
}
.how-it-works__inner {
  margin-bottom: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.how-it-works__item {
  position: relative;
  padding: 2rem 4.4rem 1.5rem 0;
  margin-left: 2rem;
  margin-bottom: 7rem;
  width: 100%;
  max-width: 49rem;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 3.5rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  &:last-child {
    margin-bottom: 0;
  }
  .text {
    position: relative;
    span {
      position: absolute;
      top: 50%;
      left: -3.5rem;
      margin-top: -11rem;
      font-family: 'Adelle Cyrillic', sans-serif;
      font-size: 18rem;
      line-height: 22.5rem;
      font-weight: bold;
      color: #C7352B;
      opacity: 0.5;
    }
    p {
      position: relative;
      z-index: 10;
    }
  }
}


.form-make-your-choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 5rem;
  border-radius: 2rem;
  max-width: 43rem;
  background-color: rgba(111, 86, 65, 0.68);
  &__header {
    color: #DFC1A8;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  &__choices {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding: 5rem 0;
  }
  &__choice-label {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 2rem;
    user-select: none;
  }
  &__choice-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &__choice-mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2.6rem;
    width: 2.6rem;
    background-color: rgba(255, 255, 255, 0.28);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
  }
  &__choice-label:hover input ~ .form-make-your-choice__choice-mark {
    border: 4px solid #FFFFFF;
  }
  &__choice-label input:checked ~ .form-make-your-choice__choice-mark {
    border: 4px solid #FFFFFF;
    background-color: $accent-color;
  }
  &__inputs {
    margin-bottom: 3.5rem;
    & input {
      padding: 1.2rem 1.6rem;
      font-size: 1.4rem;
      background: #FFFFFF;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 4px;
      width: 100%;
      margin-bottom: 2.5rem;
      &::placeholder {
        color: #504137;
      }
    }
  }
}


.effective-method {
  padding-top: 11.5rem;
  padding-bottom: 12rem;
  color: $text-color;
  .effective-method_wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .container {
    // padding-left: 26rem;
    // padding-right: 26rem;
  }
  .s_title {
    margin-bottom: 2rem;
  }
  .sub_title {
    margin-bottom: 7.5rem;
    font-size: 2.5rem;
    line-height: 3rem;
    color: #646464;
  }
  &__list-title {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 5.4rem;
    text-transform: uppercase;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2rem;
    margin-right: -2rem;
  }
  &__list-item {
    position: relative;
    width: 50%;
    padding: 1.3rem 2rem 1.3rem 6rem;
    margin-bottom: 2rem;
    font-size: 2.4rem;
    line-height: 2.9rem;
    font-weight: 500;
    &:before {
      content: '';
      position: absolute;
      top: 1.9rem;
      left: 2rem;
      display: block;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: $accent-color;
    }
  }
  &__promise {
    position: relative;
    padding-top: 3rem;
    &-mark {
      font-family: 'Adelle Cyrillic';
      font-size: 14rem;
      line-height: 1;
      color: #e8e8e8;
    }
    &-txt {
      position: absolute;
      top: 40%;
      left: 2rem;
      transform: translateY(-50%);
      font-size: 2.5rem;
      line-height: 3rem;
      text-transform: uppercase;
    }
  }
}


.get-faster {
  position: relative;
  padding-top: 13rem;
  padding-bottom: 13rem;
  color: #fff;
  background: url(../img/get-faster-bg.png) center;
  background-size: cover;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #1F130A;
  }
  .get-faster_wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .container {
    // padding-left: 26rem;
    // padding-right: 26rem;
  }
  .s_title {
    margin-bottom: 2.5rem;
  }
  .sub_title {
    margin-bottom: 5rem;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 2.9rem;
    color: #fff;
  }
}


.services-price {
  position: relative;
  padding-top: 10rem;
  padding-bottom: 14rem;
  background-color: #EFEFEF;
  .container {
    display: flex;
    flex-wrap: wrap;
    // padding-left: 26rem;
    // padding-right: 26rem;
  }
  .s_title {
    width: 100%;
    margin-bottom: 9rem;
  }
  &__left, &__right {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  &__right {
    align-items: flex-end;
    justify-content: center;
  }
  &__table {
    width: 100%;
  }
  &__group>td{
    font-size: 2rem;
    line-height: 2.4rem;
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
  &__group:first-child>td {
    padding-top: 0;
  }
  &__item-title {
    font-size: 2rem;
    line-height: 2.4rem;
    padding: 1rem 2rem 1rem 0;
    &:before {
      content: '-';
      display: inline-block;
      margin-right: 5px;
    }
  }
  &__item-price {
    font-size: 2.2rem;
    line-height: 2.6rem;
    font-weight: 700;
    padding: 1rem 0;
    vertical-align: top;
    white-space: nowrap;
  }
  .prices_banner {
    color: #fff;
  }
}


.mistakes {
  position: relative;
  padding-top: 13rem;
  padding-bottom: 13rem;
  color: #fff;
  background: url(../img/mistakes-bg.png) center;
  background-size: cover;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #1F130A;
  }
  .mistakes_wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container {
    padding-left: 46rem;
    padding-right: 46rem;
  }
  .s_title {
    margin-bottom: 10rem;
    text-transform: uppercase;
  }
  &__item {
    position: relative;
    display: flex;
    font-size: 2.5rem;
    line-height: 3.5rem;
    align-items: flex-start;
    margin-bottom: 5rem;
    padding-left: 7rem;
    &:last-child{
      margin-bottom: 0;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0.9rem;
      background: url('../img/alert.png') top left;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      width: 5.2rem;
      height: 100%;
    }
  }
  &__item-title {
    font-weight: 700;
    text-transform: uppercase;
  }
}



// ======---BUY-DEBT-----========================================================

.page-top-buy-debt {
  .top_ctn {
    text-align: left;
    justify-content: flex-start;
  }
  .top_ctn_inner {
    width: 100%;
  }
  .top_bg:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1F130A;
    opacity: 0.4;
  }
  &__title-1{
    font-size: 8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 9.5rem;
    margin-bottom: 1rem;
  }
  &__title-2{
    font-size: 6rem;
    line-height: 7.2rem;
    display: flex;
    margin-bottom: 3rem;
    .smaller {
      font-size: 3rem;
      line-height: 3.6rem;
    }
  }
  &__title-2-right {
    font-size: 13rem;
    line-height: 10.8rem;
    margin-left: 1rem;
  }
  .top_ctn_inner .buttons {
    margin-top: 3.5rem;
  }
  .page-top-advantages {
    max-width: 100%;
    margin-top: 9rem;
  }

}


.what-we-buy {
  padding-top: 13rem;
  padding-bottom: 12rem;
  color: $text-color;
  .what-we-buy_wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .container {
    // padding-left: 26rem;
    // padding-right: 26rem;
  }
  .s_title {
    margin-bottom: 2rem;
  }
  .sub_title {
    margin-bottom: 5rem;
    font-size: 2.5rem;
    line-height: 3rem;
    color: #646464;
  }
}
.what-we-buy_image {
  position: relative;
  width: 34.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .img_wrap {
    overflow: hidden;
    background: $text-color;
    img {
      display: block;
      opacity: .4;
    }
  }
}
.what-we-buy_ctn {
  padding-right: 9rem;
  width: 100%;
}
.what-we-buy_items {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-top: 4rem;
    margin-bottom: 4.5rem;
    padding-right: 2rem;
    position: relative;
    width: 33.3333333%;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .item_text {
    position: relative;
    margin-bottom: 2.4rem;
  }
  .buttons {
    position: relative;
    z-index: 10;
    margin-left: 3.5rem;
  }
}
.list_styled {
  .num {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -8rem;
    font-family: 'Adelle Cyrillic', sans-serif;
    font-weight: bold;
    font-size: 14rem;
    line-height: 17.5rem;
    color: #E8E8E8;
  }
  .text, p {
    position: relative;
    z-index: 10;
    padding-left: 3.5rem;
  }
}
.what-we-buy-end {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  &__item-1 {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-right: 9rem;
    max-width: 45rem;
  }
}


.interested {
  position: relative;
  padding-top: 13rem;
  padding-bottom: 13rem;
  color: #fff;
  background: url(../img/interested-bg.png) center;
  background-size: cover;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #1F130A;
  }
  .interested_wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .container {
    // padding-left: 26rem;
    // padding-right: 26rem;
  }
  .s_title {
    margin-bottom: 2.5rem;
  }
  .sub_title {
    margin-bottom: 5rem;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 2.9rem;
    color: #fff;
  }
}


.buying-debt-graph {
  padding-top: 12rem;
  padding-bottom: 19rem;
  background: url(../img/buying-debt-graph-bg.png) center;
  background-size: cover;
  color: $text-color;
  &_wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__list-cont {
    position: relative;
    width: 107rem;
    height: 37rem;
    margin-top: 5.6rem;
    background: url(../img/buying-debt-graph.png) center;
    background-size: 100%;
  }
  &__list-item {
    position: absolute;
    width: 50%;
    font-size: 1.8rem;
    line-height: 2.1rem;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 4.5rem;
    max-width: 38rem;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-clip: padding-box;
      transform:translateY(-0.5rem);
    }
    &:nth-child(odd):before {
      background-color: $accent-color;
      border: 0.5rem solid rgba($accent-color, 0.3);
    }
    &:nth-child(even):before {
      background-color: $text-color;
      border: 0.5rem solid rgba($text-color, 0.3);
    }
    &:nth-child(1) {
      left: -1.5rem;
      top: 5rem;
    }
    &:nth-child(2) {
      left: 15.6rem;
      top: 11rem;
    }
    &:nth-child(3) {
      left: 38.3rem;
      top: 17.8rem;
    }
    &:nth-child(4) {
      left: 57.3rem;
      top: 22.9rem;
    }
    &:nth-child(5) {
      left: 78.6rem;
      top: 29rem;
    }
    &:nth-child(6) {
      left: 105.3rem;
      top: 36.1rem;
    }
  }
  &__bottom {
    position: absolute;
    left: 0;
    bottom: -11rem;
  }
  &__bottom-txt {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 3rem;
    max-width: 48rem;
    text-transform: uppercase;
  }
}


.main-advantages {
  padding-top: 12rem;
  padding-bottom: 12rem;
  color: $text-color;
  .main-advantages_wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .container {
    // padding-left: 26rem;
    // padding-right:8rem;
  }
  .s_title {
    margin-bottom: 2rem;
  }
  .sub_title {
    margin-bottom: 5rem;
    font-size: 2.5rem;
    line-height: 3rem;
    color: #646464;
  }
}
.main-advantages_ctn {
  // padding-left: 9rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.main-advantages_items {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-top: 4rem;
    margin-bottom: 4.5rem;
    padding-right: 2rem;
    position: relative;
    width: 33.3333333%;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .item_text {
    position: relative;
    margin-bottom: 2.4rem;
  }
  .buttons {
    position: relative;
    z-index: 10;
    margin-left: 3.5rem;
  }
}
.list_styled {
  .num {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -8rem;
    font-family: 'Adelle Cyrillic', sans-serif;
    font-weight: bold;
    font-size: 14rem;
    line-height: 17.5rem;
    color: #E8E8E8;
  }
  .text, p {
    position: relative;
    z-index: 10;
    padding-left: 3.5rem;
  }
}


.s_video_compact {
  padding-top: 5rem;
  padding-bottom: 12rem;
}



// ========================ABOUT US===========================
.page_about-us {
  &__inner {
    p {
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .more-link_source {
    text-transform: none;
  }
}

.related-articles {
  &__item {
    .s_title {
      font-size: 3rem;
      line-height: 3.8rem;
      margin-bottom: 3rem;
      margin-top: 5rem;
    }
    .image {
      margin-bottom: 0;
    }
  }
}


@import "media_desctop";
@import "media";