@font-face {
  font-family: "Sf Pro Display";
  src: local("Sf Pro Display Regular"), url("../fonts/SFProDisplay-Regular.woff") format("woff"), url("../fonts/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Sf Pro Display";
  src: local("Sf Pro Display Ultralight"), url("../fonts/SFProDisplay-Ultralight.woff") format("woff"), url("../fonts/SFProDisplay-Ultralight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Sf Pro Display";
  src: local("Sf Pro Display Thin"), url("../fonts/SFProDisplay-Thin.woff") format("woff"), url("../fonts/SFProDisplay-Thin.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Sf Pro Display";
  src: local("Sf Pro Display Light"), url("../fonts/SFProDisplay-Light.woff") format("woff"), url("../fonts/SFProDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Sf Pro Display";
  src: local("Sf Pro Display Medium"), url("../fonts/SFProDisplay-Medium.woff") format("woff"), url("../fonts/SFProDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Sf Pro Display";
  src: local("Sf Pro Display Semibold"), url("../fonts/SFProDisplay-Semibold.woff") format("woff"), url("../fonts/SFProDisplay-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Sf Pro Display";
  src: local("Sf Pro Display Bold"), url("../fonts/SFProDisplay-Bold.woff") format("woff"), url("../fonts/SFProDisplay-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Sf Pro Display";
  src: local("Sf Pro Display Black"), url("../fonts/SFProDisplay-Black.woff") format("woff"), url("../fonts/SFProDisplay-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Squares";
  src: local("Squares Bold"), local("SquaresBold"), url("../fonts/SquaresBold.woff") format("woff"), url("../fonts/SquaresBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Adelle Cyrillic";
  src: local("AdelleCyrillic-Bold"), local("AdelleCyrillicBold"), url("../fonts/AdelleCyrillic-Bold.woff") format("woff"), url("../fonts/AdelleCyrillic-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
html, body {
  height: 100%;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

body {
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Sf Pro Display", sans-serif;
  font-size: 1.6rem;
  line-height: 2.3rem;
  font-weight: 400;
  color: #1F130A;
  background: transparent;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  scrollbar-width: none;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
body::-webkit-scrollbar {
  width: 0;
}
body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
body::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 10px;
}
body::-webkit-scrollbar-thumb:hover {
  background: #999;
}

*, ::after, ::before {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;
}

.wrapper {
  min-width: 320px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sr-only {
  height: 0;
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  list-style: none;
}

ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 17px;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s;
}
a:hover, a:focus {
  text-decoration: none;
  color: #C7352B;
  outline: none;
}

p {
  margin-bottom: 0;
  margin-top: 0;
}

video {
  display: flex;
  max-width: 100%;
}

input {
  padding: 0;
  outline: none;
  border: none;
  max-width: 100%;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
  outline: none !important;
  transition: all 0.2s;
}
button i, button img {
  transition: all 0.2s;
}

.bold {
  font-weight: 700;
}

.sbold {
  font-weight: 500;
}

.thin {
  font-weight: 300;
}

.accent {
  color: #C7352B;
}

.red {
  color: #C7352B;
}

.uppercase {
  text-transform: uppercase;
}

.huge {
  font-style: normal;
  font-weight: 700;
  font-size: 8rem;
  line-height: 9.5rem;
}

.checkbox {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.checkbox input[type=checkbox] {
  position: absolute;
  left: -9999px;
}

.checkbox label {
  padding: 0;
  position: relative;
  display: inline-block !important;
  margin-top: 0;
  margin-right: 1rem;
  margin-bottom: 0;
  width: 2.6rem !important;
  height: 2.6rem;
  vertical-align: top;
  outline: none;
  cursor: pointer;
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 4px;
  flex-shrink: 0;
}
.checkbox label:after {
  position: absolute;
  display: block;
  content: "";
  top: 0.3rem;
  left: 0.3rem;
  width: 1.8rem;
  height: 1.8rem;
  background: transparent;
  border-radius: 2px;
}

.checkbox input[type=checkbox]:checked + label {
  background: #fff;
}

.checkbox input[type=checkbox]:checked + label:after {
  background: #C7352B;
}

.checkbox label + span {
  font-size: 2rem;
  line-height: 1.2;
}

.checkbox a {
  font-size: 2rem;
  line-height: 1.2;
  text-decoration: underline;
  color: inherit;
}
.checkbox a:hover {
  text-decoration: none;
  color: inherit;
}

.radio {
  margin-top: 0;
  margin-bottom: 0;
}

.radio input[type=radio] {
  position: absolute;
  left: -9999px;
}

.radio label {
  padding: 0;
  position: relative;
  display: inline-block !important;
  margin-top: 0;
  margin-right: 1rem;
  margin-bottom: 0;
  width: 2.6rem !important;
  height: 2.6rem;
  vertical-align: top;
  outline: none;
  cursor: pointer;
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 4px;
  flex-shrink: 0;
}
.radio label:after {
  position: absolute;
  display: block;
  content: "";
  top: 0.3rem;
  left: 0.3rem;
  width: 1.8rem;
  height: 1.8rem;
  background: transparent;
  border-radius: 2px;
}

.radio input[type=radio]:checked + label {
  background: #fff;
}

.radio input[type=radio]:checked + label:after {
  background: #C7352B;
}

.radio label + span {
  font-size: 2rem;
  line-height: 1.2;
}

.radio a {
  font-size: 2rem;
  line-height: 1.2;
  text-decoration: underline;
  color: inherit;
}
.radio a:hover {
  text-decoration: none;
  color: inherit;
}

.selection {
  position: relative;
  color: #000;
}
.selection.active ul {
  display: block;
}
.selection .selected {
  padding: 7px 2rem;
  width: 100%;
  height: 4.5rem;
  font-size: 2rem;
  line-height: 143.2%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10em;
}
.selection .selected:hover {
  cursor: pointer;
}
.selection .selected::placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.selection .icon {
  position: absolute;
  top: 0;
  right: 0.5rem;
  width: 4rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selection .icon:hover {
  cursor: pointer;
}
.selection ul {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  margin-top: 0;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-top: none;
  display: none;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.selection ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.selection ul li.active a {
  background-color: rgba(0, 0, 0, 0.05);
}
.selection ul li:last-child {
  border-bottom: none;
}
.selection ul a {
  display: block;
  padding: 7px 2rem;
  width: 100%;
  height: 4.5rem;
  font-size: 2rem;
  line-height: 143.2%;
}
.selection ul a:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.05);
}

.buttons_wrap {
  display: flex;
  align-items: flex-start;
}

.buttons {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  height: 3.8rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 400;
  border-radius: 10em;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background: #C7352B;
  color: #fff;
}

.btn-1 {
  position: relative;
  height: 3.8rem;
  top: -1px;
  transition: all 0.2s;
  box-shadow: 0px 4px 0px #75160F;
}
.btn-1:active {
  top: 1px;
  color: #fff;
  background: #bf3329;
  box-shadow: 0px 0px 0px #75160F;
  transition: all 0.2s;
}
.btn-1:hover {
  color: #fff;
  transition: all 0.2s;
}
.btn-1:focus {
  color: #fff;
}

.btn-2 {
  border: 1px solid #cecbc9;
  background: #fff;
  color: #1F130A;
  transition: all 0.2s;
}
.btn-2:hover, .btn-2:active {
  color: #1F130A;
  background: #cecbc9;
}
.btn-2:focus {
  color: #1F130A;
}

.btn-3 {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  transition: all 0.2s;
}
.btn-3:focus {
  color: #fff;
  background: transparent;
}
.btn-3:hover, .btn-3:active {
  color: #1F130A;
  background: #fff;
}

.btn-4 {
  border: 1px solid #D04B47;
  background: #fff;
  color: #D04B47;
  transition: all 0.2s;
}
.btn-4:focus {
  background: #fff;
  color: #D04B47;
}
.btn-4:hover, .btn-4:active {
  color: #fff;
  background: #D04B47;
}

.btn-5 {
  border: 1px solid #BC9E85;
  background: transparent;
  color: #BC9E85;
  font-size: 2rem;
  line-height: 2.4rem;
  transition: all 0.2s;
}
.btn-5:focus {
  color: #BC9E85;
}
.btn-5:hover, .btn-5:active {
  color: #fff;
  background: #6F5641;
  border-color: #6F5641;
}

html {
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  font-size: 1.6rem;
  line-height: 2.3rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-120px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.modal_open .wrapper, .modal_open .main_footer {
  filter: blur(10px);
}
.container {
  padding-left: 26rem;
  padding-right: 26rem;
}

.home .container {
  position: relative;
  padding-left: 37rem;
  padding-right: 37rem;
}

.main_header {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-left: 10rem;
  padding-right: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
}
.main_header .header_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main_header .header_nav {
  display: flex;
  align-items: center;
}
.main_header.stick {
  position: fixed;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: #1F130A;
  animation: fadeInDown 0.5s ease forwards;
}

.logo {
  display: flex;
  position: relative;
  color: #fff;
}
.logo:hover, .logo:focus {
  color: #fff;
  text-decoration: none;
}
.logo__img {
  display: block;
  height: 48.66px;
  width: 56.74px;
}
.logo__title {
  font-size: 8px;
  letter-spacing: 4px;
  line-height: 1;
  position: absolute;
  left: 0;
  bottom: 0;
}
.logo__txt {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-left: 20px;
  margin-left: 10px;
  border-left: 1px solid #fff;
}

.top_menu {
  font-size: 1.6rem;
  line-height: 1.9rem;
}
.top_menu ul {
  display: flex;
  align-items: center;
}
.top_menu li {
  padding: 0 1rem;
}
.top_menu li.active a {
  color: #fff;
}
.top_menu a {
  display: block;
  padding-bottom: 8px;
  padding-top: 10px;
  color: #fff;
  border-bottom: 2px solid transparent;
}
.top_menu a:hover, .top_menu a.active {
  color: #fff;
  border-bottom: 2px solid #C7352B;
}
.top_menu .sub_li {
  position: relative;
}
.top_menu .sub_li:hover ul {
  opacity: 1;
  pointer-events: auto;
}
.top_menu .sub_li > a {
  display: flex;
  align-items: center;
}
.top_menu .sub_li > a:after {
  display: block;
  content: "";
  width: 2.4rem;
  height: 2.4rem;
  background: url(../img/arrow-drop-down.svg) no-repeat center;
  -webkit-background-size: 100%;
  background-size: 100%;
}
.top_menu .sub_li ul {
  position: absolute;
  padding: 0.5rem 0;
  top: 100%;
  left: 0;
  background-color: #000;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s;
  flex-direction: column;
  align-items: flex-start;
}

.lang_menu {
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-left: 8rem;
}
.lang_menu ul {
  position: relative;
  display: flex;
}
.lang_menu ul:hover li.unactive {
  opacity: 1;
  pointer-events: auto;
}
.lang_menu li.active a {
  color: #fff;
  display: flex;
  align-items: center;
}
.lang_menu li.active a:after {
  display: block;
  content: "";
  width: 2.4rem;
  height: 2.4rem;
  background: url(../img/arrow-drop-down.svg) no-repeat center;
  -webkit-background-size: 100%;
  background-size: 100%;
}
.lang_menu li.unactive {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #000;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s;
}
.lang_menu a {
  padding: 1rem;
  color: #fff;
  display: flex;
}
.lang_menu a:hover, .lang_menu a.active {
  color: #fff;
}

.menu_btn {
  display: none;
}

.call_btn {
  display: none;
}

.menu_mob_wrap {
  display: none;
}

.s_title {
  position: relative;
  font-weight: bold;
  font-size: 4rem;
  line-height: 4.8rem;
}
.s_title:before {
  position: absolute;
  display: block;
  content: "";
  width: 3.9rem;
  height: 5.5rem;
  top: -0.4rem;
  left: -1.4rem;
  background: url(../img/stripe-red.svg) no-repeat top left;
  -webkit-background-size: contain;
  background-size: contain;
  z-index: 0;
}
.s_title span {
  position: relative;
  z-index: 10;
}

.wrapper {
  overflow: hidden;
}

.home_top {
  position: relative;
  height: 100vh;
  background: #1F130A;
}
.home_top .top_ctn {
  padding-top: 35rem;
  padding-left: 20%;
  padding-right: 20%;
  position: relative;
  z-index: 10;
  text-align: center;
  color: #FFFFFF;
}
.home_top .t1 {
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 7.5rem;
  line-height: 9rem;
  text-transform: uppercase;
}
.home_top .t2 {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 5rem;
  line-height: 6rem;
  text-transform: uppercase;
}
.home_top .t3 {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 4rem;
  line-height: 4.8rem;
  text-transform: uppercase;
}
.home_top .buttons {
  margin-left: auto;
  margin-right: auto;
  margin-top: 13.5rem;
}
.home_top .bg_trgl {
  position: absolute;
  left: 50%;
  bottom: 1.1rem;
  margin-left: -37rem;
  width: 74rem;
}
.home_top .top_ctn_inner {
  position: relative;
  z-index: 10;
}
.home_top .top_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.home_top .top_bg img {
  height: 100%;
  min-width: 100%;
  max-width: none;
}
.home_top .mouse {
  position: absolute;
  bottom: 7rem;
  left: 50%;
  margin-left: -11px;
}

.page_top {
  position: relative;
  height: 100vh;
  width: 100%;
  background: #1F130A;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.page_top .top_ctn {
  padding-top: 7.5rem;
  padding-left: 20%;
  padding-right: 20%;
  position: relative;
  z-index: 10;
  height: 75.5%;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page_top .top_links {
  height: 24.5%;
  width: 100%;
  background: #1F130A;
  display: flex;
  align-items: center;
}
.page_top .top_links_inner {
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page_top .top_links_item {
  display: flex;
  align-items: center;
}
.page_top .top_links_item .divider {
  margin-right: 3.5rem;
  margin-left: 3.5rem;
  width: 1px;
  height: 10rem;
  background-color: #6F5641;
}
.page_top .top_links_item_icon {
  flex-shrink: 0;
}
.page_top .t1 {
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 4rem;
  line-height: 4.8rem;
  text-transform: uppercase;
}
.page_top .t2 {
  margin-bottom: 2rem;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
  text-transform: uppercase;
}
.page_top .bg_trgl {
  position: absolute;
  left: 50%;
  bottom: 1.1rem;
  margin-left: -37rem;
  width: 74rem;
}
.page_top .top_ctn_inner {
  position: relative;
  z-index: 10;
  font-size: 2rem;
  line-height: 2.4rem;
}
.page_top .top_ctn_inner .buttons {
  margin-left: auto;
  margin-right: auto;
  margin-top: 9rem;
}
.page_top .top_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.page_top .top_bg img {
  min-height: 100%;
  min-width: 100%;
  max-width: none;
}

.page_top_ban {
  position: relative;
  height: 42.2rem;
  width: 100%;
  background: #1F130A;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.page_top_ban h1, .page_top_ban .t1 {
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.2;
  text-transform: uppercase;
}
.page_top_ban h2, .page_top_ban .t2 {
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.2;
  text-transform: uppercase;
}
.page_top_ban .top_ctn {
  padding-top: 5rem;
  padding-left: 20%;
  padding-right: 20%;
  position: relative;
  z-index: 10;
  height: 100%;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page_top_ban .top_ctn_inner {
  position: relative;
  z-index: 10;
  font-size: 2rem;
  line-height: 2.4rem;
}
.page_top_ban .top_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page_top_ban .top_bg img {
  height: 100%;
  min-width: 100%;
  max-width: none;
}

.page_num {
  font-family: "Squares", sans-serif;
  font-weight: 700;
  font-size: 14.4rem;
  line-height: 16.6rem;
  position: absolute;
  top: -10rem;
}
.page_num:before {
  position: absolute;
  display: block;
  content: "";
  width: 2px;
  height: 20rem;
  left: 50%;
  margin-left: -1px;
  top: -16rem;
  background: #C7352B;
}
.page_num.black {
  margin-right: 2.5rem;
  right: 100%;
  color: #1F130A;
}
.page_num.white {
  margin-left: 2.5rem;
  left: 100%;
  color: #fff;
}
.page_num span {
  position: relative;
  z-index: 10;
}

.credits_lawyer {
  padding-top: 16rem;
  padding-bottom: 15rem;
  color: #1F130A;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.8rem;
}
.credits_lawyer .credits_lawyer_wrap {
  display: flex;
  align-items: flex-start;
  position: relative;
}
.credits_lawyer .s_title {
  margin-bottom: 5rem;
}
.credits_lawyer .btns_wrap {
  margin-top: 10rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.credits_lawyer .buttons {
  margin-right: 2.8rem;
}
.credits_lawyer .buttons:last-child {
  margin-right: 0;
}

.credits_lawyer_ctn {
  padding-left: 9rem;
  padding-top: 1.6rem;
  text-transform: uppercase;
}

.credits_lawyer_image {
  position: relative;
  width: 56.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.credits_lawyer_image .img_wrap {
  overflow: hidden;
  background: #1F130A;
}
.credits_lawyer_image .img_wrap img {
  display: block;
  opacity: 0.4;
}

.block_frame {
  position: absolute;
  top: -1.1rem;
  left: 1.1rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.block_frame img {
  flex-shrink: 0;
}
.block_frame.red {
  border: 1px solid #C7352B;
}
.block_frame.grey {
  border: 1px solid #A8A9AA;
}

.problem_solutions {
  padding-top: 19.4rem;
  padding-bottom: 10.5rem;
  color: #fff;
  background: url(../img/bg-img-4.jpg) top right;
  -webkit-background-size: cover;
  background-size: cover;
}
.problem_solutions .page_num {
  top: -14rem;
}
.problem_solutions .s_title {
  margin-bottom: 8rem;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}
.problem_solutions .problem_solutions_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.problem_solutions_inner {
  margin-bottom: 7.5rem;
  position: relative;
  display: flex;
  width: calc(100% + 11rem);
  margin-left: -5.5rem;
  margin-right: -5.5rem;
}

.problem_solutions_item {
  position: relative;
  padding: 7.5rem 4.4rem 6rem 9.5rem;
  margin-left: 5.5rem;
  margin-right: 5.5rem;
  width: 50%;
  height: 30.5rem;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 3.5rem;
  text-transform: uppercase;
  border: 1px solid #E0675E;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.problem_solutions_item .text {
  position: relative;
}
.problem_solutions_item .text span {
  position: absolute;
  top: 50%;
  left: -3.5rem;
  margin-top: -11rem;
  font-family: "Adelle Cyrillic", sans-serif;
  font-size: 18rem;
  line-height: 22.5rem;
  font-weight: bold;
  color: #C7352B;
  opacity: 0.5;
}
.problem_solutions_item .text p {
  position: relative;
  z-index: 10;
}

.credit_type {
  padding-top: 16rem;
  padding-bottom: 15rem;
  color: #1F130A;
}
.credit_type .credit_type_wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.credit_type .container {
  padding-right: 27rem;
}
.credit_type .s_title {
  margin-bottom: 5rem;
}

.credit_type_image {
  position: relative;
  width: 36.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.credit_type_image .img_wrap {
  overflow: hidden;
  background: #1F130A;
}
.credit_type_image .img_wrap img {
  display: block;
  opacity: 0.4;
}

.credit_type_ctn {
  padding-right: 9rem;
  padding-top: 3.6rem;
}

.credit_type_items {
  display: flex;
  flex-wrap: wrap;
}
.credit_type_items .item {
  margin-top: 4rem;
  margin-bottom: 4.5rem;
  position: relative;
  width: 50%;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 2.6rem;
}
.credit_type_items .item_text {
  position: relative;
  margin-bottom: 2.4rem;
}
.credit_type_items .buttons {
  position: relative;
  z-index: 10;
  margin-left: 3.5rem;
}

.list_styled .num {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -8rem;
  font-family: "Adelle Cyrillic", sans-serif;
  font-weight: bold;
  font-size: 14rem;
  line-height: 17.5rem;
  color: #E8E8E8;
}
.list_styled .text, .list_styled p {
  position: relative;
  z-index: 10;
  padding-left: 3.5rem;
}

.what_to_do {
  position: relative;
  padding-top: 19.4rem;
  padding-bottom: 10.5rem;
  color: #fff;
}
.what_to_do .page_num {
  top: -7rem;
}
.what_to_do .page_num:before {
  height: 23rem;
}
.what_to_do .bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #1F130A;
  clip-path: polygon(0 0, 100% 9%, 100% 100%, 0% 100%);
}
.what_to_do .s_title {
  margin-bottom: 11.5rem;
}
.what_to_do .container {
  position: relative;
  z-index: 10;
}
.what_to_do .what_to_do_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.what_to_do_inner {
  margin-bottom: 7.5rem;
  position: relative;
  display: flex;
  width: calc(100% + 10rem);
  margin-left: -5rem;
  margin-right: -5rem;
}

.what_to_do_item {
  position: relative;
  margin-left: 5rem;
  margin-right: 5rem;
  width: 50%;
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 3.8rem;
  text-transform: uppercase;
}
.what_to_do_item img {
  margin-bottom: 4rem;
}
.what_to_do_item .buttons {
  margin-top: 5rem;
}
.what_to_do_item .item_ctn {
  padding: 4.3rem 4rem 6rem;
  background: #C7352B;
  height: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.what_to_do_item .block_frame {
  z-index: 10;
  border: 1px solid #E0675E;
  pointer-events: none;
}

.debtor_protect {
  padding-top: 16rem;
  padding-bottom: 15rem;
  color: #1F130A;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
}
.debtor_protect .debtor_protect_wrap {
  position: relative;
  display: flex;
  align-items: flex-start;
}
.debtor_protect .s_title {
  margin-bottom: 6rem;
}
.debtor_protect .btns_wrap {
  margin-top: 10rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.debtor_protect .buttons {
  margin-right: 2.8rem;
}
.debtor_protect .buttons:last-child {
  margin-right: 0;
}

.debtor_protect_image {
  position: relative;
  width: 33.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.debtor_protect_image .img_wrap {
  overflow: hidden;
  background: #1F130A;
}
.debtor_protect_image .img_wrap img {
  display: block;
  opacity: 0.4;
}

.debtor_protect_ctn {
  padding-left: 9rem;
}

.debtor_protect_items {
  display: flex;
  flex-wrap: wrap;
}
.debtor_protect_items .item {
  margin-top: 6rem;
  margin-bottom: 6rem;
  position: relative;
  width: 50%;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  color: #1F130A;
  display: flex;
  align-items: center;
}
.debtor_protect_items .item .num {
  margin-top: -8.6rem;
}
.debtor_protect_items .item_text {
  position: relative;
}
.debtor_protect_items .buttons {
  position: relative;
  z-index: 10;
}

.registers {
  position: relative;
  padding-top: 16rem;
  padding-bottom: 10rem;
  color: #fff;
}
.registers .page_num {
  top: -8rem;
}
.registers .bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #1F130A;
  clip-path: polygon(0 9%, 100% 0, 100% 100%, 0% 100%);
}
.registers .s_title {
  font-size: 3.5rem;
  line-height: 4.2rem;
}
.registers .registers_wrap {
  position: relative;
}

.registers_inner {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: calc(100% + 19rem);
  margin-left: -9.5rem;
  margin-right: -9.5rem;
}

.registers_item {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.registers_item .item_header {
  margin-bottom: 5.3rem;
  padding-left: 7.5rem;
  height: 8.4rem;
  align-self: flex-start;
  display: flex;
  align-items: center;
}
.registers_item .item_image {
  margin-bottom: 5rem;
}
.registers_item .item_image img {
  display: block;
}

.arrests_cancel {
  padding-top: 12.4rem;
  padding-bottom: 6.5rem;
  background: #fff;
}
.arrests_cancel .page_num {
  top: -7rem;
}
.arrests_cancel .s_title {
  margin-bottom: 11.5rem;
}
.arrests_cancel .arrests_cancel_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrests_cancel_inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 10rem);
  margin-left: -5rem;
  margin-right: -5rem;
}

.arrests_cancel_item {
  position: relative;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 5rem;
  width: calc(50% - 10rem);
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.3rem;
  text-transform: uppercase;
  color: #fff;
}
.arrests_cancel_item .btns_wrap {
  margin-top: 5rem;
  display: flex;
  align-items: center;
}
.arrests_cancel_item .buttons {
  margin-right: 3rem;
}
.arrests_cancel_item .buttons:last-child {
  margin-right: 0;
}
.arrests_cancel_item .item_img {
  background: #1F130A;
}
.arrests_cancel_item .item_img img {
  display: block;
  opacity: 0.3;
  width: 100%;
}
.arrests_cancel_item .item_ctn {
  position: absolute;
  padding: 6rem 8rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.arrests_cancel_item .block_frame {
  z-index: 10;
  border: 1px solid #E0675E;
  pointer-events: none;
}

.bankruptcy {
  padding-top: 13rem;
  padding-bottom: 13rem;
  color: #fff;
  background: url(../img/bg-img-5.jpg) top right;
  -webkit-background-size: cover;
  background-size: cover;
}
.bankruptcy .s_title {
  margin-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}
.bankruptcy .bankruptcy_wrap {
  position: relative;
  display: flex;
  align-items: center;
}
.bankruptcy .text-1 {
  margin-bottom: 5.5rem;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 3.5rem;
  text-transform: uppercase;
}
.bankruptcy .text-2 {
  margin-bottom: 2rem;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3.5rem;
  color: #E5E5E5;
}
.bankruptcy .triangle {
  position: relative;
  margin-left: -6rem;
  width: 53.3rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bankruptcy .page_num {
  top: -1rem;
}

.bankruptcy_inner {
  padding-top: 5rem;
}

.loan_limitation {
  padding-top: 16rem;
  padding-bottom: 15rem;
  color: #1F130A;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
}
.loan_limitation .loan_limitation_wrap {
  position: relative;
  display: flex;
  align-items: flex-start;
}
.loan_limitation .s_title {
  margin-bottom: 6rem;
  margin-left: 1.4rem;
}
.loan_limitation .btns_wrap {
  margin-top: 10rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loan_limitation .buttons {
  margin-right: 2.8rem;
}
.loan_limitation .buttons:last-child {
  margin-right: 0;
}
.loan_limitation .page_num {
  margin-right: 2.5rem;
}

.loan_limitation_image {
  position: relative;
  width: 33.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loan_limitation_image .img_wrap {
  overflow: hidden;
  background: #1F130A;
}
.loan_limitation_image .img_wrap img {
  display: block;
  opacity: 0.4;
}

.loan_limitation_ctn {
  padding-top: 4rem;
  padding-left: 7rem;
}

.loan_limitation_items {
  display: flex;
  flex-wrap: wrap;
}
.loan_limitation_items .item {
  padding-right: 1rem;
  margin-top: 6rem;
  margin-bottom: 6rem;
  position: relative;
  width: 50%;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2rem;
  color: #1F130A;
  display: flex;
  align-items: center;
}
.loan_limitation_items .item .num {
  margin-top: -8.6rem;
}
.loan_limitation_items .item .text, .loan_limitation_items .item p {
  padding-left: 2.5rem;
}
.loan_limitation_items .item_text {
  position: relative;
}
.loan_limitation_items .buttons {
  position: relative;
  z-index: 10;
}

.reasons {
  padding-top: 15rem;
  padding-bottom: 14.5rem;
  color: #1F130A;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
}
.reasons .container {
  display: flex;
  align-items: flex-start;
}
.reasons .s_title {
  margin-bottom: 6rem;
}
.reasons .btns_wrap {
  margin-top: 10rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reasons .buttons {
  margin-right: 2.8rem;
}
.reasons .buttons:last-child {
  margin-right: 0;
}

.reasons_image {
  position: relative;
  width: 33.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reasons_image .img_wrap {
  overflow: hidden;
  background: #1F130A;
}
.reasons_image .img_wrap img {
  display: block;
  opacity: 0.4;
}

.reasons_ctn {
  padding-left: 9rem;
}

.reasons_items {
  display: flex;
  flex-wrap: wrap;
}
.reasons_items .item {
  margin-top: 6rem;
  margin-bottom: 6rem;
  position: relative;
  width: 33.3333%;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1F130A;
  display: flex;
  align-items: center;
}
.reasons_items .item .num {
  margin-top: -8.6rem;
}
.reasons_items .item p:first-child {
  padding-left: 0;
}
.reasons_items .item_text {
  position: relative;
}
.reasons_items .buttons {
  position: relative;
  z-index: 10;
}

.check_data {
  padding-top: 13rem;
  padding-bottom: 13rem;
  color: #1F130A;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  background: #EFEFEF;
}
.check_data .container {
  display: flex;
  justify-content: space-between;
}
.check_data .s_title {
  margin-bottom: 5rem;
}
.check_data .btns_wrap {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.check_data .buttons {
  margin-bottom: 3.4rem;
}
.check_data .buttons:last-child {
  margin-bottom: 0;
}

.check_data_ctn {
  padding-right: 5rem;
  padding-top: 6rem;
  text-transform: uppercase;
}

.check_data_image {
  position: relative;
  max-width: 65.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.check_data_image .img_wrap {
  overflow: hidden;
  filter: drop-shadow(0px 4px 45px rgba(0, 0, 0, 0.14));
}
.check_data_image .img_wrap img {
  display: block;
}

.how_it_work {
  padding-top: 15rem;
  padding-bottom: 15rem;
  color: #fff;
  font-size: 2rem;
  line-height: 2.4rem;
  background: url(../img/bg-img-6.jpg) top right;
  -webkit-background-size: cover;
  background-size: cover;
}
.how_it_work .s_title {
  margin-bottom: 2.3rem;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}
.how_it_work .container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.how_it_work_items {
  margin-top: 10.5rem;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 3.5rem;
  text-transform: uppercase;
}
.how_it_work_items li {
  position: relative;
  padding-left: 3.5rem;
  margin-bottom: 10.5rem;
}
.how_it_work_items li:last-child {
  margin-bottom: 0;
}
.how_it_work_items span {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -11rem;
  font-family: "Adelle Cyrillic", sans-serif;
  font-size: 18rem;
  line-height: 22.5rem;
  font-weight: bold;
  color: #C7352B;
  opacity: 0.5;
}
.how_it_work_items p {
  position: relative;
  z-index: 10;
}

.what_you_get {
  padding-top: 15rem;
  padding-bottom: 12rem;
}
.what_you_get .container {
  display: flex;
  align-items: center;
}
.what_you_get .s_title {
  margin-bottom: 3rem;
}

.what_you_get_image {
  position: relative;
  width: 33.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.what_you_get_image .img_wrap {
  overflow: hidden;
  background: #1F130A;
}
.what_you_get_image .img_wrap img {
  display: block;
  opacity: 0.4;
}

.what_you_get_ctn {
  padding-right: 5rem;
}

.what_you_get_items {
  display: flex;
  flex-wrap: wrap;
}
.what_you_get_items .item {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
  position: relative;
  width: 33.3333%;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  color: #1F130A;
}
.what_you_get_items .item_text {
  position: relative;
  margin-bottom: 2.4rem;
}

.prices {
  padding-top: 10.5rem;
  padding-bottom: 16rem;
  color: #fff;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
  background: #1F130A;
}
.prices .s_title {
  margin-bottom: 10rem;
}

.prices_inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prices_banner {
  position: relative;
  width: 52.4rem;
  text-align: center;
}
.prices_banner .buttons {
  margin-top: 5rem;
}
.prices_banner .t1 {
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.6rem;
}
.prices_banner .item_ctn {
  padding: 6rem 4rem 6rem;
  background: #C7352B;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.prices_banner .block_frame {
  z-index: 10;
  border: 1px solid #E0675E;
  pointer-events: none;
}

.price_list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.price_list li:last-child {
  margin-bottom: 0;
}
.price_list .price {
  padding-left: 4.5rem;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 2.6rem;
}
.price_list .sub_title {
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 700;
}

.our_publications {
  padding-top: 10.5rem;
  padding-bottom: 10rem;
}
.our_publications .s_title {
  margin-bottom: 10.5rem;
}
.our_publications .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.our_publications_inner {
  position: relative;
  padding-bottom: 4rem;
  margin-left: -3rem;
  margin-right: -3rem;
  width: calc(100% + 6rem);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.our_publications_item {
  margin-bottom: 6rem;
  margin-left: 3rem;
  margin-right: 3rem;
  position: relative;
  width: calc(33.3333% - 6rem);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.our_publications_item .img_wrap {
  overflow: hidden;
  background: #1F130A;
}
.our_publications_item .img_wrap img {
  display: block;
  opacity: 0.4;
}
.our_publications_item .text {
  position: absolute;
  padding: 3rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-weight: 600;
  font-size: 2rem;
  line-height: 3.5rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.our_publications_item .block_frame {
  top: auto;
  bottom: -1rem;
  right: -1rem;
}

.s_video {
  padding-top: 15rem;
  padding-bottom: 15rem;
  background-color: #fff;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
}
.s_video .s_title {
  margin-bottom: 5rem;
}
.s_video .buttons {
  margin-top: 5rem;
}
.s_video .container {
  display: flex;
  align-items: center;
}
.s_video .video_frame {
  width: 63rem;
  height: 35.5rem;
}

.video_frame {
  overflow: hidden;
  position: relative;
}
.video_frame iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.s_video_inner {
  padding-left: 10rem;
  padding-bottom: 2rem;
}

.loans_escape {
  padding-top: 13rem;
  padding-bottom: 13rem;
  color: #1F130A;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
}
.loans_escape .container {
  display: flex;
  align-items: flex-start;
}
.loans_escape .s_title {
  margin-bottom: 0;
}
.loans_escape .btns_wrap {
  margin-top: 10rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loans_escape .buttons {
  margin-right: 2.8rem;
}
.loans_escape .buttons:last-child {
  margin-right: 0;
}

.loans_escape_image {
  position: relative;
  width: 33.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loans_escape_image .img_wrap {
  height: 53rem;
  overflow: hidden;
  background: #1F130A;
}
.loans_escape_image .img_wrap img {
  display: block;
  opacity: 0.4;
}

.loans_escape_ctn {
  padding-left: 9rem;
}

.loans_escape_items {
  display: flex;
  flex-wrap: wrap;
}
.loans_escape_items .item {
  margin-top: 6rem;
  margin-bottom: 5.5rem;
  position: relative;
  width: 33.3333%;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  color: #1F130A;
  display: flex;
  align-items: center;
}
.loans_escape_items .item .num {
  margin-top: -8.6rem;
}
.loans_escape_items .item p:first-child {
  padding-left: 0;
}
.loans_escape_items .item_text {
  position: relative;
}
.loans_escape_items .buttons {
  position: relative;
  z-index: 10;
}

.pic_banner {
  padding-top: 16rem;
  padding-bottom: 14rem;
}
.pic_banner .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pic_banner_left {
  max-width: 50rem;
}
.pic_banner_left .pic_logo {
  margin-bottom: 5rem;
}
.pic_banner_left .t1 {
  margin-bottom: 5.5rem;
  font-size: 2.4rem;
  line-height: 2.9rem;
}
.pic_banner_left .t2 {
  width: 22.5rem;
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: #C7352B;
  text-transform: uppercase;
  margin-right: 3rem;
}
.pic_banner_left .text_arrow {
  display: flex;
  align-items: center;
}

.pic_banner_right {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.pic_banner_right .item {
  position: relative;
  margin-bottom: 2rem;
  padding: 2.7rem 3.7rem;
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 2.6rem;
  text-transform: uppercase;
  border: 1px solid #C7352B;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pic_banner_right .item:last-child {
  margin-bottom: 0;
}
.pic_banner_right .item:before {
  position: absolute;
  display: block;
  content: "";
  top: 50%;
  left: -1px;
  margin-top: -2.5rem;
  height: 5rem;
  width: 2px;
  background-color: #fff;
}
.pic_banner_right .item:after {
  position: absolute;
  display: block;
  content: "";
  top: 50%;
  right: -1px;
  margin-top: -2.5rem;
  height: 5rem;
  width: 2px;
  background-color: #fff;
}
.pic_banner_right .item p {
  padding-right: 3rem;
}

.pic_banner_2 {
  margin-top: 70px;
  display: none;
  text-align: center;
}
.pic_banner_2 .pic_banner_top {
  padding: 40px 30px 45px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  background: #C7352B;
  border: 1px solid #C7352B;
}
.pic_banner_2 .t1 {
  margin-bottom: 36px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  text-transform: uppercase;
}
.pic_banner_2 .pic_banner_bottom {
  border: 1px solid #C7352B;
  padding: 20px 30px 25px;
  color: #C7352B;
  font-size: 20px;
  line-height: 24px;
}
.pic_banner_2 address {
  font-style: normal;
}
.pic_banner_2 .address {
  margin-bottom: 35px;
}
.pic_banner_2 .phones a {
  margin-bottom: 25px;
  display: block;
}
.pic_banner_2 .phones a:hover {
  color: #fff;
}
.pic_banner_2 .phones a:last-child {
  margin-bottom: 0;
}

.microcredit_banner {
  padding-top: 17rem;
  padding-bottom: 12.5rem;
  background: #1F130A;
  position: relative;
  color: #fff;
}
.microcredit_banner .s_title {
  margin-bottom: 2.5rem;
}
.microcredit_banner .container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.microcredit_banner .item {
  width: 40%;
}
.microcredit_banner .trgls {
  position: absolute;
}
.microcredit_banner .trgl-1 {
  left: 50%;
  top: 50%;
  margin-top: -16rem;
  margin-left: -18rem;
  width: 35.5rem;
}
.microcredit_banner .trgl-2 {
  left: 9rem;
  bottom: 8rem;
  width: 7.5rem;
  transform: rotateZ(30deg);
  filter: blur(5px);
}
.microcredit_banner .trgl-3 {
  right: 9rem;
  top: 9rem;
  width: 11rem;
  transform: rotateZ(-30deg);
  filter: blur(5px);
}

.statements_complaints {
  padding-top: 10rem;
  padding-bottom: 13rem;
}
.statements_complaints .container {
  display: flex;
  align-items: center;
}
.statements_complaints .image {
  margin-right: 5rem;
  flex-shrink: 0;
}
.statements_complaints .s_title {
  margin-bottom: 7rem;
}
.statements_complaints .btns_wrap {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.statements_complaints .buttons {
  margin-right: 3rem;
}
.statements_complaints .buttons:last-child {
  margin-right: 0;
}

.s_question_answer {
  padding-top: 10rem;
  padding-bottom: 10rem;
  background: #EFEFEF;
}
.s_question_answer .container {
  display: flex;
  justify-content: space-between;
}
.s_question_answer .left .s_title {
  margin-bottom: 7rem;
}
.s_question_answer .right .s_title {
  margin-bottom: 10rem;
}

.question_answer {
  width: 65rem;
  height: 51rem;
  padding-right: 5rem;
  overflow-y: auto;
}
.question_answer .item {
  margin-bottom: 4rem;
}
.question_answer .question {
  display: flex;
  align-items: center;
}
.question_answer .question.active .question_icon {
  background: #C7352B url(../img/cross-white.svg) no-repeat center;
}
.question_answer .question.active .question_text {
  color: #C7352B;
}
.question_answer .question_icon {
  margin-right: 2rem;
  font-weight: 500;
  font-size: 2.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  color: #1F130A;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff url(../img/plus-black.svg) no-repeat center;
}
.question_answer .question_text {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
}
.question_answer .answer {
  padding-top: 1rem;
  padding-left: 5rem;
  font-size: 1.6rem;
  line-height: 3rem;
  display: none;
}

.ask_form {
  width: 50rem;
}
.ask_form .form_title {
  margin-bottom: 2.5rem;
  font-size: 2.5rem;
  line-height: 3rem;
  color: #5A5A5A;
}
.ask_form .form_field {
  margin-bottom: 3rem;
}
.ask_form input {
  color: #504137;
  font-size: 14px;
  line-height: 17px;
}
.ask_form input::placeholder {
  color: #504137;
}
.ask_form textarea {
  height: 16rem;
  font-size: 14px;
  line-height: 17px;
}
.ask_form textarea::placeholder {
  color: #504137;
}

.form_field input, .form_field textarea {
  padding: 1.2rem 1.8rem;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  resize: vertical;
}

.s_faqs {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.s_faqs .s_title {
  margin-bottom: 6rem;
}

.faqs_list {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
}
.faqs_list li {
  border-bottom: 1px solid #CFCFCF;
}
.faqs_list a {
  padding: 3rem;
  position: relative;
  display: flex;
  align-items: center;
}
.faqs_list a:hover, .faqs_list a:focus {
  color: #C7352B;
}
.faqs_list a:before {
  margin-right: 2rem;
  display: block;
  content: "";
  width: 2.8rem;
  height: 2.8rem;
  background: url(../img/newspaper.svg) no-repeat center;
  -webkit-background-size: contain;
  background-size: contain;
}

.page_form {
  padding: 6rem 5rem;
  width: 43rem;
  background: rgba(111, 86, 65, 0.68);
  box-shadow: 6px 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 2rem;
}
.page_form form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page_form .form_title {
  margin-bottom: 50px;
  text-align: center;
  font-size: 2.5rem;
  line-height: 3.5rem;
  text-transform: uppercase;
  color: #DFC1A8;
}
.page_form .checks_inline {
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page_form .form_field {
  width: 100%;
}
.page_form .form_field input {
  margin-bottom: 2.5rem;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  height: 4rem;
  font-size: 14px;
  line-height: 4rem;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
}
.page_form .submit {
  margin-top: 3.5rem;
}

.main_footer {
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #fff;
}
.main_footer address {
  margin-bottom: 2.7rem;
  font-style: normal;
}
.main_footer .email {
  margin-bottom: 3rem;
  font-weight: 400;
  color: #3DADFF;
}
.main_footer .email a:hover {
  text-decoration: underline;
}
.main_footer .phones {
  display: flex;
}
.main_footer .phones a {
  margin-right: 2.3rem;
}
.main_footer .links a {
  display: block;
  margin-bottom: 3rem;
}
.main_footer .links a:last-child {
  margin-bottom: 0;
}
.main_footer .map {
  height: 68rem;
}

.main_footer_top {
  padding: 10rem 26rem;
  width: 100%;
  background: #1F130A;
  display: flex;
  justify-content: space-between;
}

.footer_socials {
  margin-top: 4rem;
  display: flex;
  align-items: center;
}
.footer_socials a {
  margin-right: 3rem;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_socials a:last-child {
  margin-right: 0;
}

.s_socials {
  padding-top: 10rem;
  padding-bottom: 10rem;
  background: #1F130A;
  color: #fff;
}
.s_socials .s_title {
  margin-bottom: 7.5rem;
}
.s_socials .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socials {
  display: flex;
  align-items: center;
}
.socials a {
  margin-right: 6rem;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.socials a:last-child {
  margin-right: 0;
}

.page_services .s_video {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.how_pay_services {
  padding-top: 14.5rem;
}
.how_pay_services .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.how_pay_services .s_title {
  margin-bottom: 9.5rem;
}
.how_pay_services .inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.services_block {
  padding: 10rem 11.5rem;
  background: #EFEFEF;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
}
.services_block:nth-child(2n) {
  background: transparent;
}
.services_block .s_title {
  margin-left: 2rem;
  margin-bottom: 6rem;
}

.services_list.no_border li {
  border-bottom-color: transparent;
}
.services_list li {
  padding: 2rem 1.5rem;
  border-bottom: 1px solid #A8A9AA;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.services_list .text {
  width: 75%;
}
.services_list .price {
  width: 25%;
  flex-shrink: 0;
  font-weight: 700;
  font-size: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.services_list .accent {
  margin-left: 2rem;
  font-size: 14px;
  line-height: 17px;
}
.services_list .pay_btn:hover, .services_list .accent:hover {
  text-decoration: underline;
}

.page_contacts_inner {
  padding-top: 10.5rem;
  padding-bottom: 16rem;
  background: #EFEFEF;
}
.page_contacts_inner .container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.page_contacts_inner .left .s_title {
  margin-bottom: 14rem;
}
.page_contacts_inner .right .s_title {
  margin-bottom: 6rem;
}
.page_contacts_inner .socials a {
  margin-right: 3.4rem;
  width: 3.4rem;
  height: 3.4rem;
}
.page_contacts_inner .socials a:last-child {
  margin-right: 0;
}
.page_contacts_inner .address {
  margin-bottom: 5rem;
}
.page_contacts_inner address {
  font-style: normal;
}
.page_contacts_inner .email {
  margin-bottom: 6rem;
  font-weight: 400;
  color: #3DADFF;
}
.page_contacts_inner .email a:hover {
  text-decoration: underline;
}
.page_contacts_inner .phones {
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
}
.page_contacts_inner .contacts_item {
  position: relative;
  padding-left: 5rem;
}
.page_contacts_inner .contacts_item_icon {
  position: absolute;
  top: 0;
  left: 0;
}

.page_articles_inner {
  padding-top: 5rem;
}

.page_article_inner {
  width: 83rem;
  margin-left: auto;
  margin-right: auto;
}

.article_wrap {
  padding-top: 5rem;
  padding-bottom: 10rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.8rem;
  text-align: justify;
  color: #1F130A;
}
.article_wrap .image {
  margin-bottom: 3rem;
}
.article_wrap a {
  color: #C7352B;
  text-decoration: underline;
}
.article_wrap a:hover, .article_wrap a:focus {
  color: #C7352B;
  text-decoration: none;
}
.article_wrap .more_link {
  margin-top: 2rem;
  text-transform: uppercase;
}
.article_wrap .s_title {
  margin-top: 6rem;
  margin-bottom: 4rem;
  text-align: left;
}
.article_wrap ul {
  margin-top: 5px;
  margin-bottom: 5px;
}
.article_wrap .red_banner {
  margin-top: 5rem;
  margin-bottom: 3rem;
}
.article_wrap .quotted {
  position: relative;
  margin-top: 5rem;
  padding: 4rem 12rem;
  font-style: italic;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.8rem;
  border: 1px solid #1F130A;
}
.article_wrap .quote {
  padding: 1.5rem;
  position: absolute;
  background-color: #fff;
}
.article_wrap .quote.left {
  top: -1.6rem;
  left: -1.5rem;
}
.article_wrap .quote.right {
  bottom: -2.2rem;
  right: -1.5rem;
}

.page_article_top img {
  width: 100%;
}
.page_article_top .caption {
  margin-bottom: 2rem;
}

.red_banner {
  position: relative;
  width: 100%;
  text-align: left;
  color: #fff;
}
.red_banner .t1 {
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.6rem;
}
.red_banner .t2 {
  font-size: 2rem;
  line-height: 2.4rem;
}
.red_banner .item_ctn {
  padding: 4rem 7.5rem 5rem;
  background: #C7352B;
}
.red_banner .block_frame {
  z-index: 10;
  border: 1px solid #E0675E;
  pointer-events: none;
}

.top_articles {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.comments-area {
  width: 65rem;
  height: 51rem;
  padding-right: 5rem;
  overflow-y: auto;
}
.comments-area .children {
  padding-left: 62px;
}

.comment-wrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.comment-img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  overflow: hidden;
  background: #fff url(../img/anonim-user.svg) no-repeat center;
  -webkit-background-size: 50%;
  background-size: 50%;
}

.comment-body {
  padding-bottom: 15px;
  padding-top: 10px;
  padding-left: 16px;
  position: relative;
  width: 100%;
  font-size: 16px;
  line-height: 23px;
}

.comment-author {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.comment-date {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 143.34%;
}

.comment-reply {
  padding-left: 9.5px;
  padding-right: 9.5px;
  position: absolute;
  top: 0;
  right: 0;
  height: 24px;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid #cecbc9;
  background: #fff;
  color: #1F130A;
  text-transform: uppercase;
  transition: all 0.2s;
  border-radius: 10em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.comment-reply:hover, .comment-reply:focus {
  background: #cecbc9;
}
.comment-reply:hover a, .comment-reply:focus a {
  color: #1F130A;
}

.map {
  position: relative;
  height: 680px;
  width: 100%;
}
.map:after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url("../img/map-with-marker.png") no-repeat center;
  -webkit-background-size: cover;
  background-size: cover;
}
.map a {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
}

.map_2 {
  width: 100%;
  height: 65rem;
}
.map_2 iframe {
  display: block;
  width: 100%;
  height: 65rem;
}

.mfp-bg {
  opacity: 0.4;
  background: #000;
}

.mfp-wrap {
  top: 0 !important;
  position: fixed !important;
}

.mfp-container {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mfp-content {
  position: relative;
  margin-top: 0;
  max-height: 100%;
  width: auto !important;
}
.mfp-content .ask-question {
  display: block;
}

.mfp-close {
  position: absolute;
  right: -45px;
  top: -13px;
  opacity: 1;
  transform: rotateZ(0deg);
  transition: transform 0.5s;
}
.mfp-close:hover {
  transform: rotateZ(360deg);
}

.mfp-close:active {
  top: -13px;
}

button.mfp-close {
  font-size: 0;
  background: transparent url(../img/close-white.svg) no-repeat center;
  background-size: 18px;
}

.ask-question {
  display: none;
}

.popup,
.popup_anket {
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 40;
}

.ask-question {
  padding: 35px;
  background: #fff;
  box-shadow: 0 0 10px #000;
  position: relative;
  width: 100%;
  max-width: 440px;
}

.ask-question-heading {
  font-size: 25px;
  line-height: 1.2;
  font-weight: 400;
  padding-left: 20px;
  border-left: 2px solid #ca3931;
  margin-bottom: 35px;
}

.ask-question form {
  display: flex;
  flex-direction: column;
}

.ask-question form input,
.ask-question form textarea {
  font-family: SF-Pro-Displaty-Thin;
  font-size: 14px;
  padding: 15px;
  resize: none;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  outline: 0;
  width: 100%;
}

.ask-question form input:focus,
.ask-question form textarea:focus {
  border: 1px solid #ca3931;
}

.ask-question form textarea {
  height: 120px;
  margin-bottom: 10px;
}

.ask-question form .buttons {
  position: relative;
  top: -1px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  width: auto;
  height: 3.8rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 400;
  border: none;
  border-radius: 10em;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background: #C7352B;
  color: #fff;
  transition: all 0.2s;
  box-shadow: 0px 2px 0px #75160F;
}
.ask-question form .buttons:active {
  top: 1px;
  color: #fff;
  background: #bf3329;
  box-shadow: 0px 0px 0px #75160F;
  transition: all 0.2s;
  border: none;
  height: 3.8rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 400;
}
.ask-question form .buttons:hover {
  color: #fff;
  transition: all 0.2s;
  height: 3.8rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 400;
  cursor: pointer;
}
.ask-question form .buttons:focus {
  color: #fff;
  border: none;
  height: 3.8rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 400;
}

.wpcf7 p {
  text-align: center;
}

.ask-question-footer {
  color: #828282;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}

.ask-question hr {
  width: 100%;
  height: 2px;
  margin: 0;
}

.page_top_w-adv .top_ctn {
  text-align: left;
  justify-content: flex-start;
}
.page_top_w-adv .top_ctn_inner {
  width: 100%;
}
.page_top_w-adv .top_bg:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1F130A;
  opacity: 0.4;
}
.page_top_w-adv .title-1 {
  font-size: 8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 9.5rem;
  margin-bottom: 1rem;
}
.page_top_w-adv .title-2 {
  font-size: 6rem;
  line-height: 7.2rem;
}
.page_top_w-adv .title-2 > .larger {
  font-size: 7rem;
  line-height: 8.4rem;
}
.page_top_w-adv .top_ctn_inner .buttons {
  margin-top: 3.5rem;
}

.page-top-advantages {
  display: flex;
  flex-wrap: wrap;
  max-width: 70%;
  margin-top: 5rem;
  padding-left: 1.9rem;
}
.page-top-advantages__item {
  position: relative;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 5.5rem;
  margin-right: 5rem;
  margin-bottom: 2.5rem;
  max-width: 50%;
}
.page-top-advantages__item:before {
  content: "";
  position: absolute;
  display: block;
  width: 3.9rem;
  height: 5.5rem;
  top: 0;
  left: -1.9rem;
  background: url(../img/stripe-red.svg) no-repeat top left;
  -webkit-background-size: contain;
  background-size: contain;
  z-index: -1;
}

.img_wrap {
  width: 100%;
}

.what-if {
  padding-top: 16rem;
  padding-bottom: 15rem;
  color: #1F130A;
}
.what-if .what-if_wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.what-if .container {
  padding-left: 26rem;
  padding-right: 8rem;
}
.what-if .s_title {
  margin-bottom: 2rem;
}
.what-if .sub_title {
  margin-bottom: 5rem;
  font-size: 2.5rem;
  line-height: 3rem;
  color: #646464;
}

.what-if_image {
  position: relative;
  width: 34.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.what-if_image .img_wrap {
  overflow: hidden;
  background: #1F130A;
}
.what-if_image .img_wrap img {
  display: block;
  opacity: 0.4;
}

.what-if_ctn {
  padding-left: 9rem;
  width: 100%;
}

.what-if_items {
  display: flex;
  flex-wrap: wrap;
}
.what-if_items .item {
  margin-top: 4rem;
  margin-bottom: 4.5rem;
  padding-right: 2rem;
  position: relative;
  width: 33.3333333%;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
}
.what-if_items .item_text {
  position: relative;
  margin-bottom: 2.4rem;
}
.what-if_items .buttons {
  position: relative;
  z-index: 10;
  margin-left: 3.5rem;
}

.list_styled .num {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -8rem;
  font-family: "Adelle Cyrillic", sans-serif;
  font-weight: bold;
  font-size: 14rem;
  line-height: 17.5rem;
  color: #E8E8E8;
}
.list_styled .text, .list_styled p {
  position: relative;
  z-index: 10;
  padding-left: 3.5rem;
}

.what-if-end {
  display: flex;
  align-items: center;
  margin-top: 3rem;
}
.what-if-end__item-1 {
  font-size: 2.5rem;
  line-height: 3rem;
  margin-right: 9rem;
  max-width: 45rem;
}

.how-it-works {
  position: relative;
  padding-top: 13rem;
  padding-bottom: 15rem;
  color: #fff;
  background: url(../img/how-it-works-bg.png) center;
  background-size: cover;
}
.how-it-works:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #1F130A;
}
.how-it-works .s_title {
  margin-bottom: 2rem;
}
.how-it-works .sub_title {
  margin-bottom: 9rem;
  font-size: 2rem;
  line-height: 2.4rem;
}
.how-it-works .how-it-works__left, .how-it-works .how-it-works__right {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}
.how-it-works .container {
  display: flex;
}

.how-it-works__inner {
  margin-bottom: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.how-it-works__item {
  position: relative;
  padding: 2rem 4.4rem 1.5rem 0;
  margin-left: 2rem;
  margin-bottom: 7rem;
  width: 100%;
  max-width: 49rem;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 3.5rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.how-it-works__item:last-child {
  margin-bottom: 0;
}
.how-it-works__item .text {
  position: relative;
}
.how-it-works__item .text span {
  position: absolute;
  top: 50%;
  left: -3.5rem;
  margin-top: -11rem;
  font-family: "Adelle Cyrillic", sans-serif;
  font-size: 18rem;
  line-height: 22.5rem;
  font-weight: bold;
  color: #C7352B;
  opacity: 0.5;
}
.how-it-works__item .text p {
  position: relative;
  z-index: 10;
}

.form-make-your-choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 5rem;
  border-radius: 2rem;
  max-width: 43rem;
  background-color: rgba(111, 86, 65, 0.68);
}
.form-make-your-choice__header {
  color: #DFC1A8;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3.5rem;
}
.form-make-your-choice__choices {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 5rem 0;
}
.form-make-your-choice__choice-label {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 2rem;
  user-select: none;
}
.form-make-your-choice__choice-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.form-make-your-choice__choice-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.6rem;
  width: 2.6rem;
  background-color: rgba(255, 255, 255, 0.28);
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
}
.form-make-your-choice__choice-label:hover input ~ .form-make-your-choice__choice-mark {
  border: 4px solid #FFFFFF;
}
.form-make-your-choice__choice-label input:checked ~ .form-make-your-choice__choice-mark {
  border: 4px solid #FFFFFF;
  background-color: #C7352B;
}
.form-make-your-choice__inputs {
  margin-bottom: 3.5rem;
}
.form-make-your-choice__inputs input {
  padding: 1.2rem 1.6rem;
  font-size: 1.4rem;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 2.5rem;
}
.form-make-your-choice__inputs input::placeholder {
  color: #504137;
}

.effective-method {
  padding-top: 11.5rem;
  padding-bottom: 12rem;
  color: #1F130A;
}
.effective-method .effective-method_wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.effective-method .s_title {
  margin-bottom: 2rem;
}
.effective-method .sub_title {
  margin-bottom: 7.5rem;
  font-size: 2.5rem;
  line-height: 3rem;
  color: #646464;
}
.effective-method__list-title {
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 5.4rem;
  text-transform: uppercase;
}
.effective-method__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -2rem;
  margin-right: -2rem;
}
.effective-method__list-item {
  position: relative;
  width: 50%;
  padding: 1.3rem 2rem 1.3rem 6rem;
  margin-bottom: 2rem;
  font-size: 2.4rem;
  line-height: 2.9rem;
  font-weight: 500;
}
.effective-method__list-item:before {
  content: "";
  position: absolute;
  top: 1.9rem;
  left: 2rem;
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #C7352B;
}
.effective-method__promise {
  position: relative;
  padding-top: 3rem;
}
.effective-method__promise-mark {
  font-family: "Adelle Cyrillic";
  font-size: 14rem;
  line-height: 1;
  color: #e8e8e8;
}
.effective-method__promise-txt {
  position: absolute;
  top: 40%;
  left: 2rem;
  transform: translateY(-50%);
  font-size: 2.5rem;
  line-height: 3rem;
  text-transform: uppercase;
}

.get-faster {
  position: relative;
  padding-top: 13rem;
  padding-bottom: 13rem;
  color: #fff;
  background: url(../img/get-faster-bg.png) center;
  background-size: cover;
}
.get-faster:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #1F130A;
}
.get-faster .get-faster_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.get-faster .s_title {
  margin-bottom: 2.5rem;
}
.get-faster .sub_title {
  margin-bottom: 5rem;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.9rem;
  color: #fff;
}

.services-price {
  position: relative;
  padding-top: 10rem;
  padding-bottom: 14rem;
  background-color: #EFEFEF;
}
.services-price .container {
  display: flex;
  flex-wrap: wrap;
}
.services-price .s_title {
  width: 100%;
  margin-bottom: 9rem;
}
.services-price__left, .services-price__right {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.services-price__right {
  align-items: flex-end;
  justify-content: center;
}
.services-price__table {
  width: 100%;
}
.services-price__group > td {
  font-size: 2rem;
  line-height: 2.4rem;
  padding-top: 3rem;
  padding-bottom: 1rem;
}
.services-price__group:first-child > td {
  padding-top: 0;
}
.services-price__item-title {
  font-size: 2rem;
  line-height: 2.4rem;
  padding: 1rem 2rem 1rem 0;
}
.services-price__item-title:before {
  content: "-";
  display: inline-block;
  margin-right: 5px;
}
.services-price__item-price {
  font-size: 2.2rem;
  line-height: 2.6rem;
  font-weight: 700;
  padding: 1rem 0;
  vertical-align: top;
  white-space: nowrap;
}
.services-price .prices_banner {
  color: #fff;
}

.mistakes {
  position: relative;
  padding-top: 13rem;
  padding-bottom: 13rem;
  color: #fff;
  background: url(../img/mistakes-bg.png) center;
  background-size: cover;
}
.mistakes:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #1F130A;
}
.mistakes .mistakes_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mistakes .container {
  padding-left: 46rem;
  padding-right: 46rem;
}
.mistakes .s_title {
  margin-bottom: 10rem;
  text-transform: uppercase;
}
.mistakes__item {
  position: relative;
  display: flex;
  font-size: 2.5rem;
  line-height: 3.5rem;
  align-items: flex-start;
  margin-bottom: 5rem;
  padding-left: 7rem;
}
.mistakes__item:last-child {
  margin-bottom: 0;
}
.mistakes__item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.9rem;
  background: url("../img/alert.png") top left;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  width: 5.2rem;
  height: 100%;
}
.mistakes__item-title {
  font-weight: 700;
  text-transform: uppercase;
}

.page-top-buy-debt .top_ctn {
  text-align: left;
  justify-content: flex-start;
}
.page-top-buy-debt .top_ctn_inner {
  width: 100%;
}
.page-top-buy-debt .top_bg:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1F130A;
  opacity: 0.4;
}
.page-top-buy-debt__title-1 {
  font-size: 8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 9.5rem;
  margin-bottom: 1rem;
}
.page-top-buy-debt__title-2 {
  font-size: 6rem;
  line-height: 7.2rem;
  display: flex;
  margin-bottom: 3rem;
}
.page-top-buy-debt__title-2 .smaller {
  font-size: 3rem;
  line-height: 3.6rem;
}
.page-top-buy-debt__title-2-right {
  font-size: 13rem;
  line-height: 10.8rem;
  margin-left: 1rem;
}
.page-top-buy-debt .top_ctn_inner .buttons {
  margin-top: 3.5rem;
}
.page-top-buy-debt .page-top-advantages {
  max-width: 100%;
  margin-top: 9rem;
}

.what-we-buy {
  padding-top: 13rem;
  padding-bottom: 12rem;
  color: #1F130A;
}
.what-we-buy .what-we-buy_wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.what-we-buy .s_title {
  margin-bottom: 2rem;
}
.what-we-buy .sub_title {
  margin-bottom: 5rem;
  font-size: 2.5rem;
  line-height: 3rem;
  color: #646464;
}

.what-we-buy_image {
  position: relative;
  width: 34.8rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.what-we-buy_image .img_wrap {
  overflow: hidden;
  background: #1F130A;
}
.what-we-buy_image .img_wrap img {
  display: block;
  opacity: 0.4;
}

.what-we-buy_ctn {
  padding-right: 9rem;
  width: 100%;
}

.what-we-buy_items {
  display: flex;
  flex-wrap: wrap;
}
.what-we-buy_items .item {
  margin-top: 4rem;
  margin-bottom: 4.5rem;
  padding-right: 2rem;
  position: relative;
  width: 33.3333333%;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
}
.what-we-buy_items .item_text {
  position: relative;
  margin-bottom: 2.4rem;
}
.what-we-buy_items .buttons {
  position: relative;
  z-index: 10;
  margin-left: 3.5rem;
}

.list_styled .num {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -8rem;
  font-family: "Adelle Cyrillic", sans-serif;
  font-weight: bold;
  font-size: 14rem;
  line-height: 17.5rem;
  color: #E8E8E8;
}
.list_styled .text, .list_styled p {
  position: relative;
  z-index: 10;
  padding-left: 3.5rem;
}

.what-we-buy-end {
  display: flex;
  align-items: center;
  margin-top: 3rem;
}
.what-we-buy-end__item-1 {
  font-size: 2.5rem;
  line-height: 3rem;
  margin-right: 9rem;
  max-width: 45rem;
}

.interested {
  position: relative;
  padding-top: 13rem;
  padding-bottom: 13rem;
  color: #fff;
  background: url(../img/interested-bg.png) center;
  background-size: cover;
}
.interested:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #1F130A;
}
.interested .interested_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.interested .s_title {
  margin-bottom: 2.5rem;
}
.interested .sub_title {
  margin-bottom: 5rem;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.9rem;
  color: #fff;
}

.buying-debt-graph {
  padding-top: 12rem;
  padding-bottom: 19rem;
  background: url(../img/buying-debt-graph-bg.png) center;
  background-size: cover;
  color: #1F130A;
}
.buying-debt-graph_wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.buying-debt-graph__list-cont {
  position: relative;
  width: 107rem;
  height: 37rem;
  margin-top: 5.6rem;
  background: url(../img/buying-debt-graph.png) center;
  background-size: 100%;
}
.buying-debt-graph__list-item {
  position: absolute;
  width: 50%;
  font-size: 1.8rem;
  line-height: 2.1rem;
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 4.5rem;
  max-width: 38rem;
}
.buying-debt-graph__list-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-clip: padding-box;
  transform: translateY(-0.5rem);
}
.buying-debt-graph__list-item:nth-child(odd):before {
  background-color: #C7352B;
  border: 0.5rem solid rgba(199, 53, 43, 0.3);
}
.buying-debt-graph__list-item:nth-child(even):before {
  background-color: #1F130A;
  border: 0.5rem solid rgba(31, 19, 10, 0.3);
}
.buying-debt-graph__list-item:nth-child(1) {
  left: -1.5rem;
  top: 5rem;
}
.buying-debt-graph__list-item:nth-child(2) {
  left: 15.6rem;
  top: 11rem;
}
.buying-debt-graph__list-item:nth-child(3) {
  left: 38.3rem;
  top: 17.8rem;
}
.buying-debt-graph__list-item:nth-child(4) {
  left: 57.3rem;
  top: 22.9rem;
}
.buying-debt-graph__list-item:nth-child(5) {
  left: 78.6rem;
  top: 29rem;
}
.buying-debt-graph__list-item:nth-child(6) {
  left: 105.3rem;
  top: 36.1rem;
}
.buying-debt-graph__bottom {
  position: absolute;
  left: 0;
  bottom: -11rem;
}
.buying-debt-graph__bottom-txt {
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 3rem;
  max-width: 48rem;
  text-transform: uppercase;
}

.main-advantages {
  padding-top: 12rem;
  padding-bottom: 12rem;
  color: #1F130A;
}
.main-advantages .main-advantages_wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-advantages .s_title {
  margin-bottom: 2rem;
}
.main-advantages .sub_title {
  margin-bottom: 5rem;
  font-size: 2.5rem;
  line-height: 3rem;
  color: #646464;
}

.main-advantages_ctn {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.main-advantages_items {
  display: flex;
  flex-wrap: wrap;
}
.main-advantages_items .item {
  margin-top: 4rem;
  margin-bottom: 4.5rem;
  padding-right: 2rem;
  position: relative;
  width: 33.3333333%;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
}
.main-advantages_items .item_text {
  position: relative;
  margin-bottom: 2.4rem;
}
.main-advantages_items .buttons {
  position: relative;
  z-index: 10;
  margin-left: 3.5rem;
}

.list_styled .num {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -8rem;
  font-family: "Adelle Cyrillic", sans-serif;
  font-weight: bold;
  font-size: 14rem;
  line-height: 17.5rem;
  color: #E8E8E8;
}
.list_styled .text, .list_styled p {
  position: relative;
  z-index: 10;
  padding-left: 3.5rem;
}

.s_video_compact {
  padding-top: 5rem;
  padding-bottom: 12rem;
}

.page_about-us__inner p {
  margin-bottom: 2rem;
}
.page_about-us__inner p:last-child {
  margin-bottom: 0;
}
.page_about-us .more-link_source {
  text-transform: none;
}

.related-articles__item .s_title {
  font-size: 3rem;
  line-height: 3.8rem;
  margin-bottom: 3rem;
  margin-top: 5rem;
}
.related-articles__item .image {
  margin-bottom: 0;
}

@media (max-width: 1900px) {
  html {
    font-size: 9px;
  }
}
@media (max-width: 1679.98px) {
  html {
    font-size: 8.5px;
  }

  .home .container {
    position: relative;
    padding-left: 32rem;
    padding-right: 32rem;
  }

  .home_top .top_ctn {
    padding-top: 33rem;
  }
}
@media (max-width: 1599.98px) {
  html {
    font-size: 8px;
  }

  body {
    font-size: 14px;
  }

  .top_menu, .lang_menu {
    font-size: 14px;
    line-height: 17px;
  }

  .container {
    position: relative;
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .home .container {
    position: relative;
    padding-left: 26rem;
    padding-right: 26rem;
  }

  .what-if .container {
    padding-left: 20rem;
    padding-right: 8rem;
  }

  .main_footer_top {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .question_answer {
    width: 70rem;
  }

  .comments-area {
    width: 70rem;
  }
}
@media (max-width: 1439.98px) {
  html {
    font-size: 7.5px;
  }

  .home_top .top_ctn {
    padding-left: 0;
    padding-right: 0;
  }
  .home_top .bg_trgl {
    width: 70rem;
    margin-left: -35rem;
  }
  .home_top .buttons {
    margin-top: 10rem;
  }
  .home_top .mouse {
    bottom: 3rem;
  }
}
@media (max-width: 1365.98px) {
  html {
    font-size: 7px;
  }

  .top_menu, .lang_menu {
    font-size: 12px;
  }

  .container {
    position: relative;
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .home .container {
    position: relative;
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .what-if .container {
    padding-left: 13rem;
    padding-right: 8rem;
  }

  .mistakes .container {
    padding-left: 26rem;
    padding-right: 26rem;
  }

  .main_footer_top,
.main_header {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .page_num {
    display: none;
  }

  .home_top .top_ctn {
    padding-top: 25rem;
  }
  .home_top .bg_trgl {
    width: 60rem;
    margin-left: -30rem;
  }
  .home_top .buttons {
    margin-top: 7rem;
  }
  .home_top .mouse {
    bottom: 3rem;
  }

  .pic_banner_left {
    max-width: 35rem;
  }
  .pic_banner_left .text_arrow {
    display: none;
  }

  .buying-debt-graph__list-cont {
    margin-left: -26rem;
  }

  .page-top-advantages {
    max-width: 90%;
  }

  .what-if-end__item-1 {
    margin-right: 7rem;
  }
}
@media (min-width: 992px) and (max-width: 1280px) and (orientation: portrait) {
  html {
    font-size: 10px;
  }
}
@media (max-width: 1023.98px) {
  .visible_mob {
    display: flex !important;
  }

  .hidden_mob {
    display: none !important;
  }

  main.active {
    filter: blur(2px);
  }

  .top_menu, .lang_menu {
    display: none;
  }

  .call_btn {
    display: block;
    margin-right: 40px;
    margin-left: auto;
  }
  .call_btn img {
    display: block;
  }

  .menu_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
  }
  .menu_btn.active .is_opened {
    display: none;
  }
  .menu_btn.active .is_closed {
    display: block;
  }
  .menu_btn .is_closed {
    display: none;
  }
  .menu_btn img {
    display: block;
  }

  .main_header {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 74px;
    width: 100%;
    background: #1F130A;
    display: flex;
    align-items: center;
  }
  .header_inner {
    width: 100%;
  }
  .header_inner > a {
    position: relative;
    z-index: 110;
  }

  .menu_mob_wrap {
    padding: 30px 50px 65px;
    display: block;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    background-color: #fff;
  }

  .header_nav {
    padding: 0;
    padding-top: 74px;
    position: fixed;
    right: -105vw;
    z-index: 100;
    top: 0;
    left: auto;
    width: 60%;
    height: 100vh;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    background-color: transparent;
    text-align: left;
    display: flex;
    justify-content: flex-end;
    transition: right 0.5s ease-out;
  }
  .header_nav.active {
    right: 0;
    transition: right 0.5s ease;
  }
  .header_nav li {
    margin-bottom: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .header_nav li a {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .header_nav li a:hover {
    color: #1F130A;
  }

  .top_menu_mob {
    text-align: left;
  }
  .top_menu_mob .sub_li {
    position: relative;
  }
  .top_menu_mob .sub_li > a {
    display: flex;
    align-items: flex-start;
  }
  .top_menu_mob .sub_li > a:after {
    display: block;
    content: "";
    width: 2.4rem;
    height: 2.4rem;
    background: url(../img/arrow-drop-down-2.svg) no-repeat center;
    -webkit-background-size: 100%;
    background-size: 100%;
  }
  .top_menu_mob .sub_li ul {
    padding-top: 20px;
    padding-left: 20px;
    display: none;
  }
  .top_menu_mob .sub_li li {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .lang_menu_mob {
    text-align: left;
  }
  .lang_menu_mob .unactive {
    display: none;
  }
  .lang_menu_mob .active a {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }
  .lang_menu_mob .active a:after {
    display: block;
    content: "";
    width: 2.4rem;
    height: 2.4rem;
    background: url(../img/arrow-drop-down-2.svg) no-repeat center;
    -webkit-background-size: 100%;
    background-size: 100%;
  }

  html {
    font-size: 10px;
  }

  body {
    font-size: 14px;
    line-height: 25px;
  }

  .container {
    padding-left: 100px;
    padding-right: 100px;
  }

  .home .container {
    padding-left: 100px;
    padding-right: 100px;
  }

  .main_footer_top,
.main_header {
    padding-left: 30px;
    padding-right: 30px;
  }

  .s_title {
    font-size: 30px;
    line-height: 36px;
  }
  .s_title:before {
    width: 3rem;
    height: 4.5rem;
    left: -1rem;
  }

  .radio {
    display: flex;
  }

  .radio label {
    width: 21px !important;
    height: 21px;
  }
  .radio label:after {
    top: 3px;
    left: 3px;
    width: 13px;
    height: 13px;
  }

  .popup_default {
    width: 100%;
  }

  .popup_default .body {
    width: 100%;
  }

  body.modal-open {
    height: 100vh;
    overflow: hidden;
  }

  .mfp-content {
    width: 100% !important;
  }

  .mfp-container {
    padding: 40px 20px 10px;
  }

  .mfp-close {
    right: -9px;
    top: -48px;
  }

  .mfp-close:active {
    top: -48px;
  }

  .home_top {
    position: relative;
    height: 100vh;
    background: #1F130A;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .home_top .top_ctn {
    padding-top: 74px;
    padding-bottom: 74px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .home_top h1, .home_top .t1 {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
  }
  .home_top h2, .home_top .t2 {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
  .home_top h3, .home_top .t3 {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }
  .home_top .buttons {
    margin-top: 50px;
  }
  .home_top .bg_trgl {
    display: none;
  }
  .home_top .mouse {
    display: none;
  }

  .list_styled .num {
    font-size: 80px;
    line-height: 100px;
    margin-top: -48px;
  }

  .list_styled .text, .list_styled p {
    padding-left: 18px;
  }

  .credits_lawyer {
    padding-top: 60px;
    padding-bottom: 100px;
  }
  .credits_lawyer .s_title {
    margin-bottom: 40px;
  }
  .credits_lawyer .btns_wrap {
    margin-top: 60px;
  }

  .credits_lawyer_image {
    display: none;
  }

  .credits_lawyer_ctn {
    padding-left: 0;
  }

  .problem_solutions {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .problem_solutions .s_title {
    margin-bottom: 50px;
  }

  .problem_solutions_inner {
    padding-bottom: 10px;
    width: 100%;
    margin: 0;
    flex-wrap: wrap;
  }

  .problem_solutions_item {
    padding: 50px 60px;
    margin-bottom: 40px;
    width: 100%;
    height: 250px;
    margin-left: 0;
    margin-right: 0;
    font-size: 20px;
    line-height: 24px;
  }
  .problem_solutions_item .text span {
    font-size: 120px;
    left: -25px;
  }

  .credit_type {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .credit_type .s_title {
    margin-bottom: 30px;
  }

  .credit_type_image {
    display: none;
  }

  .credit_type_ctn {
    padding-top: 0;
    padding-right: 0;
  }

  .credit_type_items .item {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 21px;
  }
  .credit_type_items .item .text {
    white-space: nowrap;
  }

  .what_to_do {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .what_to_do .s_title {
    margin-bottom: 60px;
  }
  .what_to_do .bg {
    clip-path: polygon(0 0, 100% 3%, 100% 100%, 0% 100%);
  }

  .what_to_do_inner {
    width: 100%;
    margin: 0;
    flex-wrap: wrap;
  }

  .what_to_do_item {
    margin-bottom: 40px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }
  .what_to_do_item:last-child {
    margin-bottom: 0;
  }

  .debtor_protect {
    padding-top: 60px;
    padding-bottom: 50px;
  }
  .debtor_protect .s_title {
    margin-bottom: 30px;
  }

  .debtor_protect_image {
    display: none;
  }

  .debtor_protect_ctn {
    padding-left: 0;
  }

  .debtor_protect_items .item {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 21px;
  }
  .debtor_protect_items .item .num {
    margin-top: -48px;
  }
  .debtor_protect_items .item_text {
    height: 42px;
    display: flex;
    align-items: center;
  }

  .arrests_cancel {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .arrests_cancel .s_title {
    margin-bottom: 50px;
  }

  .arrests_cancel_inner {
    width: 100%;
    margin: 0;
  }

  .arrests_cancel_item {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;
    text-align: center;
  }
  .arrests_cancel_item:last-child {
    margin-bottom: 0;
  }

  .bankruptcy {
    padding-top: 60px;
    padding-bottom: 100px;
  }

  .bankruptcy .triangle {
    display: none;
  }

  .loan_limitation {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .loan_limitation .s_title {
    margin-bottom: 30px;
  }

  .loan_limitation_image {
    display: none;
  }

  .loan_limitation_ctn {
    padding-left: 0;
    padding-top: 0;
  }

  .loan_limitation_items .item {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 21px;
  }
  .loan_limitation_items .item .num {
    margin-top: -48px;
  }

  .registers {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .registers .bg {
    clip-path: polygon(0 3%, 100% 0, 100% 100%, 0% 100%);
  }

  .registers_item .item_header {
    height: 120px;
    margin-bottom: 30px;
    padding-left: 30px;
    justify-content: center;
  }

  .loans_escape {
    padding-top: 60px;
    padding-bottom: 70px;
  }
  .loans_escape .s_title {
    margin-bottom: 30px;
  }
  .loans_escape .btns_wrap {
    margin-top: 60px;
  }

  .loans_escape_image {
    display: none;
  }

  .loans_escape_ctn {
    padding-left: 0;
  }

  .loans_escape_items .item {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 50%;
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    align-items: flex-start;
  }
  .loans_escape_items .item .num {
    top: -24px;
    margin-top: 0;
  }
  .loans_escape_items .item_text {
    height: 42px;
    display: flex;
    align-items: center;
  }

  .what_you_get {
    padding-top: 60px;
    padding-bottom: 70px;
  }
  .what_you_get .s_title {
    margin-bottom: 30px;
  }

  .what_you_get_image {
    display: none;
  }

  .what_you_get_ctn {
    padding-right: 0;
  }

  .what_you_get_items .item {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 50%;
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    align-items: flex-start;
  }
  .what_you_get_items .item .num {
    margin-left: -5px;
    top: -24px;
    margin-top: 0;
  }
  .what_you_get_items .item_text {
    margin-bottom: 0;
    height: 42px;
    display: flex;
    align-items: center;
  }

  .reasons {
    padding-top: 60px;
    padding-bottom: 70px;
  }
  .reasons .s_title {
    margin-bottom: 30px;
  }
  .reasons .btns_wrap {
    margin-top: 60px;
  }

  .reasons_image {
    display: none;
  }

  .reasons_ctn {
    padding-left: 0;
  }

  .reasons_items .item {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 50%;
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    align-items: flex-start;
  }
  .reasons_items .item .num {
    top: -24px;
    margin-top: 0;
  }
  .reasons_items .item_text {
    height: 42px;
    display: flex;
    align-items: center;
  }
  .reasons_items .accent {
    left: 0 !important;
  }

  .how_it_work {
    padding-top: 60px;
    padding-bottom: 0;
  }
  .how_it_work .container {
    flex-direction: column;
  }
  .how_it_work .page_form {
    position: relative;
    margin-top: 70px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 60px;
    border-radius: 0;
    align-self: center;
  }
  .how_it_work .page_form:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: -500px;
    height: 100%;
    width: 5000px;
    background: #5f4a38;
  }
  .how_it_work .page_form form {
    position: relative;
    z-index: 10;
  }

  .how_it_work_items {
    margin-top: 50px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
  }
  .how_it_work_items li {
    padding-left: 18px;
    margin-bottom: 50px;
  }
  .how_it_work_items li:last-child {
    margin-bottom: 0;
  }
  .how_it_work_items span {
    margin-top: -47px;
    font-weight: 700;
    font-size: 80px;
    line-height: 100px;
  }

  .prices {
    padding-top: 70px;
    padding-bottom: 100px;
  }
  .prices .s_title {
    margin-bottom: 50px;
  }

  .prices_inner {
    flex-direction: column;
  }

  .price_list {
    margin-bottom: 65px;
  }
  .price_list .price {
    white-space: nowrap;
  }

  .prices_banner {
    width: 100%;
  }
  .prices_banner .t1 {
    margin-bottom: 20px;
  }

  .pic_banner {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .pic_banner .container {
    display: block;
  }

  .pic_banner_left {
    margin-bottom: 30px;
  }
  .pic_banner_left .pic_logo {
    margin-bottom: 30px;
    width: 161px;
  }
  .pic_banner_left .t1 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
  }

  .pic_banner_right .item {
    margin-bottom: 30px;
    display: block;
    font-size: 22px;
    line-height: 26px;
  }
  .pic_banner_right .item:last-child {
    margin-bottom: 0;
  }
  .pic_banner_right .item p {
    padding-right: 0;
  }
  .pic_banner_right .item .buttons {
    margin-top: 30px;
  }

  .pic_banner_2 {
    display: block;
  }

  .microcredit_banner {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .microcredit_banner .container {
    display: block;
  }
  .microcredit_banner .trgl-1 {
    display: none;
  }
  .microcredit_banner .item {
    margin-bottom: 30px;
    width: 100%;
  }

  .statements_complaints {
    position: relative;
    padding-top: 65px;
    padding-bottom: 100px;
  }
  .statements_complaints .container {
    display: block;
    position: static;
  }
  .statements_complaints .s_title {
    margin-bottom: 100px;
  }
  .statements_complaints .btns_wrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .statements_complaints .buttons {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .statements_complaints .buttons:last-child {
    margin-bottom: 0;
  }
  .statements_complaints .image {
    position: absolute;
    width: 246px;
    right: 40px;
    bottom: 30px;
  }

  .s_socials {
    padding-top: 70px;
    padding-bottom: 100px;
  }
  .s_socials .s_title {
    margin-bottom: 130px;
  }

  .socials {
    width: 290px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .socials a {
    display: block;
    width: 45px;
    height: 45px;
    margin-bottom: 40px;
    margin-right: 25px;
    margin-left: 25px;
  }
  .socials a:last-child {
    margin-right: 25px;
  }
  .socials a img {
    width: 100%;
  }

  .our_publications {
    padding-top: 75px;
    padding-bottom: 100px;
  }
  .our_publications .s_title {
    margin-bottom: 65px;
    align-self: flex-start;
  }

  .our_publications_inner {
    padding-bottom: 40px;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .our_publications_item {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 30px;
  }
  .our_publications_item .img_wrap {
    width: 100%;
  }
  .our_publications_item img {
    width: 100%;
  }

  .s_video {
    padding-top: 100px;
    padding-bottom: 0px;
  }
  .s_video .container {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
  .s_video .video_frame {
    width: 100%;
    height: 56vw;
  }
  .s_video .buttons {
    margin-top: 40px;
  }
  .s_video .s_title {
    margin-bottom: 30px;
  }
  .s_video br {
    display: none;
  }

  .s_video_inner {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 45px;
  }

  .s_question_answer {
    padding-top: 75px;
    padding-bottom: 60px;
  }
  .s_question_answer .container {
    display: block;
  }
  .s_question_answer .left {
    display: none;
  }
  .s_question_answer .left .s_title {
    margin-bottom: 30px;
  }
  .s_question_answer .right .s_title {
    margin-bottom: 30px;
  }

  .s_faqs {
    padding-top: 75px;
    padding-bottom: 60px;
  }
  .s_faqs .s_title {
    margin-bottom: 44px;
  }

  .question_answer {
    width: 100%;
    padding-right: 0;
  }
  .question_answer .question_text {
    font-size: 20px;
    line-height: 24px;
  }
  .question_answer .item {
    margin-bottom: 30px;
  }

  .comments-area {
    width: 100%;
    padding-right: 0;
  }

  .check_data {
    padding-top: 75px;
    padding-bottom: 100px;
  }
  .check_data .s_title {
    margin-bottom: 70px;
  }
  .check_data .btns_wrap {
    margin-top: 0;
  }

  .check_data_ctn {
    padding-top: 0;
    padding-right: 0;
  }

  .check_data_image {
    display: none;
  }

  .page_form {
    width: 100%;
  }

  .how_pay_services {
    padding-top: 100px;
    padding-bottom: 60px;
  }
  .how_pay_services .s_title {
    display: none;
  }
  .how_pay_services .inner {
    flex-direction: column;
    align-items: center;
  }
  .how_pay_services .buttons {
    margin-bottom: 40px;
  }

  .page_services .s_video {
    padding-top: 0;
    display: none;
  }

  .services_block {
    padding: 70px 30px 60px;
  }

  .services_block .s_title {
    margin-bottom: 50px;
  }

  .services_list li {
    align-items: flex-start;
  }

  .services_list .price {
    width: 110px;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-end;
  }

  .services_list .pay_btn {
    margin-top: 10px;
    margin-left: 0;
  }

  .google_reviews {
    display: none;
  }

  .page_top {
    height: 85vh;
  }
  .page_top .top_ctn {
    height: 100%;
    padding-top: 85px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
  }
  .page_top .top_ctn br {
    display: none;
  }
  .page_top .top_links {
    display: none;
  }
  .page_top h1, .page_top .t1 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 40px;
  }
  .page_top h1 .accent, .page_top .t1 .accent {
    font-size: 40px;
    line-height: 48px;
    display: block;
  }
  .page_top h2, .page_top .t2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }
  .page_top .top_ctn_inner .buttons {
    margin-top: 120px;
  }

  .page_top_ban .top_ctn {
    padding-top: 120px;
    padding-bottom: 100px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .page_top_ban .top_bg {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page_top_ban .top_bg img {
    width: auto;
    height: auto;
    flex-shrink: 0;
  }

  .page_top_ban .top_ctn_inner {
    text-align: left;
  }

  .page_contacts_inner {
    padding-top: 75px;
    padding-bottom: 100px;
  }
  .page_contacts_inner .container {
    display: flex;
  }
  .page_contacts_inner .left {
    padding-bottom: 50px;
  }
  .page_contacts_inner .left .s_title {
    margin-bottom: 50px;
  }
  .page_contacts_inner .right .s_title {
    margin-bottom: 50px;
  }
  .page_contacts_inner .address {
    margin-bottom: 30px;
  }
  .page_contacts_inner .email {
    margin-bottom: 30px;
  }
  .page_contacts_inner .phones {
    margin-bottom: 30px;
  }
  .page_contacts_inner .contacts_item {
    padding-left: 4rem;
  }
  .page_contacts_inner .socials {
    padding-top: 20px;
    width: 290px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .page_contacts_inner .socials a {
    display: block;
    width: 45px;
    height: 45px;
    margin-bottom: 40px;
    margin-right: 50px;
    margin-left: 0;
  }
  .page_contacts_inner .socials a:last-child {
    margin-right: 50px;
  }
  .page_contacts_inner .socials a img {
    width: 100%;
  }

  .ask_form {
    width: 100%;
  }

  .page_article_inner {
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
  }

  .article_wrap {
    padding-bottom: 80px;
  }

  .article_wrap .quotted {
    padding: 30px 40px;
  }

  .article_wrap .quote {
    width: 40px;
    padding: 10px;
  }

  .red_banner .item_ctn {
    padding: 30px 40px;
  }

  .main_footer_top {
    padding-top: 60px;
    padding-bottom: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .footer_socials {
    margin-top: 60px;
    padding-bottom: 40px;
    width: 290px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .footer_socials a {
    display: block;
    width: 45px;
    height: 45px;
    margin-bottom: 40px;
    margin-right: 25px;
    margin-left: 25px;
  }
  .footer_socials a:last-child {
    margin-right: 25px;
  }
  .footer_socials a img {
    width: 100%;
  }

  .main_footer .logo {
    margin-left: auto;
    margin-right: auto;
    width: 260px;
  }
  .main_footer .contacts {
    margin-bottom: 100px;
    text-align: center;
  }
  .main_footer .address {
    margin-bottom: 25px;
  }
  .main_footer .email {
    margin-bottom: 25px;
  }
  .main_footer .phones {
    flex-direction: column;
    align-items: center;
  }
  .main_footer .phones a {
    margin-right: 0;
    margin-bottom: 25px;
  }
  .main_footer .phones a:last-child {
    margin-bottom: 0;
  }
  .main_footer .links {
    text-align: center;
  }

  .map, .map_2 {
    display: none;
  }

  .page_top_w-adv .top_ctn, .page-top-buy-debt .top_ctn {
    padding: 85px 100px 40px;
  }

  .page-top-advantages__item {
    font-size: 1.8rem;
    line-height: 2.1rem;
    margin-bottom: 4rem;
  }
  .page-top-advantages__item:before {
    height: 4.5rem;
    left: -1.5rem;
    top: -1.5rem;
    width: 3rem;
  }

  .page_top_w-adv .title-1 {
    font-size: 4rem;
    line-height: 4.8rem;
  }
  .page_top_w-adv .title-2 {
    font-size: 4.8rem;
    line-height: 5.7rem;
  }
  .page_top_w-adv .title-2 .larger {
    font-size: inherit;
    line-height: inherit;
  }
  .page_top_w-adv .top_ctn_inner > p {
    display: none;
  }
  .page_top_w-adv a.buttons {
    margin-top: 5rem !important;
  }

  .what-if {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .what-if_image {
    display: none;
  }
  .what-if_ctn {
    padding-left: 0;
  }
  .what-if .container {
    padding-left: 100px;
    padding-right: 100px;
  }
  .what-if .sub_title {
    margin-bottom: 3rem;
    font-size: 2rem;
    line-height: 3rem;
  }
  .what-if .what-if_items .item {
    font-size: 1.8rem;
    line-height: 2.1rem;
    margin-top: 0;
    margin-bottom: 3rem;
    width: 60%;
  }
  .what-if .what-if-end {
    flex-direction: column;
    align-items: flex-start;
  }
  .what-if .what-if-end__item-1 {
    margin-right: 5rem;
    margin-bottom: 5rem;
  }

  .how-it-works {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .how-it-works__left {
    width: 45%;
  }
  .how-it-works__right {
    width: 55%;
  }
  .how-it-works__item {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
  .form-make-your-choice {
    padding: 6rem 2rem;
  }

  .effective-method {
    padding-top: 6rem;
    padding-bottom: 2rem;
  }
  .effective-method .sub_title {
    margin-bottom: 3rem;
    font-size: 2rem;
    line-height: 3rem;
  }
  .effective-method__list-title {
    margin-bottom: 3rem;
    font-size: 1.8rem;
    line-height: 2.1rem;
  }
  .effective-method__list-item {
    font-size: 1.8rem;
    line-height: 2.1rem;
  }

  .get-faster {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .get-faster .sub_title {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .services-price {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .services-price .s_title {
    margin-bottom: 5rem;
  }
  .services-price__left {
    width: 100%;
    padding-bottom: 5rem;
  }
  .services-price__right {
    width: 100%;
  }

  .mistakes {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .mistakes .container {
    padding-left: 100px;
    padding-right: 100px;
  }
  .mistakes .s_title {
    margin-bottom: 5rem;
  }
  .mistakes__item {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .mistakes__item-title {
    margin-bottom: 1.5rem;
  }

  .page-top-buy-debt h1.page-top-buy-debt__title-1 {
    font-size: 3.7rem;
    line-height: 4.4rem;
    margin-bottom: 1rem;
  }
  .page-top-buy-debt h2.page-top-buy-debt__title-2 {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }
  .page-top-buy-debt h2.page-top-buy-debt__title-2 .smaller {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  .page-top-buy-debt__title-2-right {
    font-size: 7rem;
    line-height: 6.4rem;
  }
  .page-top-buy-debt .page-top-advantages {
    margin-top: 5rem;
    max-width: 90%;
  }
  .page-top-buy-debt .top_ctn_inner > p {
    display: none;
  }
  .page-top-buy-debt a.buttons {
    margin-top: 5rem !important;
  }

  .what-we-buy {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .what-we-buy_image {
    display: none;
  }
  .what-we-buy_ctn {
    padding-left: 0;
  }
  .what-we-buy .container {
    padding-left: 100px;
    padding-right: 100px;
  }
  .what-we-buy .sub_title {
    margin-bottom: 3rem;
    font-size: 2rem;
    line-height: 3rem;
  }
  .what-we-buy .what-we-buy_items {
    margin-top: 5rem;
  }
  .what-we-buy .what-we-buy_items .item {
    font-size: 1.8rem;
    line-height: 2.1rem;
    margin-top: 0;
    margin-bottom: 3rem;
    width: 100%;
  }
  .what-we-buy .what-we-buy-end__item-1 {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-right: 7rem;
  }

  .interested {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .interested .sub_title {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .main-advantages {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .main-advantages .main-advantages_items .item {
    width: 100%;
    margin-bottom: 0;
  }

  .page_buy-debt .s_video {
    padding-top: 0;
    padding-bottom: 6rem;
  }

  .buying-debt-graph {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .buying-debt-graph .buying-debt-graph_wrap {
    align-items: flex-start;
  }
  .buying-debt-graph__list-cont {
    background: none;
    margin-top: 5rem;
    margin-left: 0;
    width: unset;
    height: unset;
  }
  .buying-debt-graph__list-item {
    position: relative;
    left: unset !important;
    top: unset !important;
    width: 50%;
    margin-bottom: 3rem;
  }
  .buying-debt-graph__bottom {
    position: relative;
    bottom: unset;
    width: 70%;
  }
}
@media (max-width: 900px) {
  .logo__txt {
    font-size: 10px;
    padding-left: 10px;
  }

  .call_btn {
    margin-right: 20px;
  }

  .header_nav {
    width: 80%;
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .home .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .page_top .top_ctn {
    text-align: left;
  }

  .credits_lawyer {
    padding-bottom: 70px;
  }
  .credits_lawyer .btns_wrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .credits_lawyer .buttons {
    margin-bottom: 30px;
    margin-right: 0;
  }

  .credit_type_items .item {
    width: 100%;
  }

  .debtor_protect_items .item {
    width: 100%;
  }

  .loan_limitation_items .item {
    width: 100%;
  }

  .registers_inner {
    width: 100%;
    margin: 0;
    flex-wrap: wrap;
  }

  .registers_item {
    margin-bottom: 40px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .registers_item:last-child {
    margin-bottom: 0;
  }
  .registers_item .item_header {
    height: auto;
    padding-left: 0;
  }

  .what_to_do_item {
    font-size: 20px;
    line-height: 24px;
  }

  .arrests_cancel_item {
    font-size: 20px;
    line-height: 24px;
  }
  .arrests_cancel_item .item_ctn {
    padding: 30px 20px;
  }
  .arrests_cancel_item .btns_wrap {
    margin-top: 40px;
  }
  .arrests_cancel_item .buttons {
    height: 34px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
  }

  .loans_escape_items .item {
    width: 100%;
  }

  .what_you_get_items .item {
    width: 100%;
  }

  .reasons .item {
    width: 100%;
  }

  .prices_banner {
    text-align: left;
  }
  .prices_banner .item_ctn {
    padding-left: 30px;
    padding-right: 30px;
    display: block;
  }

  .statements_complaints .image {
    right: -170px;
  }

  .s_video_inner {
    padding-left: 30px;
    padding-right: 30px;
  }

  .how_pay_services .buttons {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
  }

  .services_wrap .container {
    padding-left: 0;
    padding-right: 0;
  }

  .services_block {
    padding-left: 0;
    padding-right: 0;
  }
  .services_block > p {
    padding-left: 30px;
    padding-right: 30px;
  }

  .services_list li {
    padding-left: 30px;
    padding-right: 30px;
  }

  .page_article_inner {
    padding-left: 30px;
    padding-right: 30px;
  }

  .page_contacts_inner .container {
    display: block;
  }

  .page-top-buy-debt .top_ctn, .page_top_w-adv .top_ctn {
    padding: 120px 30px 40px;
  }

  .container {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .page_top {
    height: unset;
  }

  .s_title {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }
  .s_title:before {
    height: 4rem;
    left: -1rem;
    width: 3.9rem;
  }

  .page-top-advantages__item {
    font-size: 1.8rem;
    line-height: 2.1rem;
    margin-bottom: 4rem;
    max-width: 100%;
    margin-right: 0;
  }
  .page-top-advantages__item:before {
    height: 3.5rem;
    left: -1rem;
    top: -0.5rem;
    width: 3rem;
  }

  .page_top_w-adv .title-1 {
    font-size: 3rem;
    line-height: 3.6rem;
  }
  .page_top_w-adv .title-2 {
    font-size: 3.8rem;
    line-height: 4.5rem;
  }
  .page_top_w-adv .title-2 .larger {
    font-size: inherit;
    line-height: inherit;
  }
  .page_top_w-adv .top_ctn_inner > p {
    display: none;
  }
  .page_top_w-adv a.buttons {
    margin-top: 3rem !important;
  }

  .what-if {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .what-if_image {
    display: none;
  }
  .what-if_ctn {
    padding-left: 0;
  }
  .what-if .container {
    padding-left: 100px;
    padding-right: 100px;
  }
  .what-if .sub_title {
    margin-bottom: 3rem;
    font-size: 2rem;
    line-height: 3rem;
  }
  .what-if .what-if_items .item {
    font-size: 1.8rem;
    line-height: 2.1rem;
    margin-top: 0;
    margin-bottom: 3rem;
    width: 100%;
  }
  .what-if .what-if_items .item:last-child {
    margin-bottom: 0;
  }
  .what-if .what-if-end {
    flex-direction: column;
    align-items: flex-start;
  }
  .what-if .what-if-end__item-1 {
    margin-right: 5rem;
    margin-bottom: 5rem;
  }

  .how-it-works {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background: none;
    padding-top: 0;
    padding-bottom: 0;
  }
  .how-it-works .container {
    flex-direction: column;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .how-it-works .s_title {
    font-size: 3rem;
    line-height: 3.6rem;
  }
  .how-it-works__left {
    width: 100% !important;
    padding: 5rem 30px 8rem 30px;
    background: url(../img/how-it-works-bg.png) 30% 50%;
    background-size: cover;
  }
  .how-it-works__right {
    width: 100% !important;
  }
  .how-it-works .sub_title {
    margin-bottom: 5rem;
  }
  .how-it-works__item {
    font-size: 1.8rem;
    line-height: 2.5rem;
    padding-right: 0;
    margin-bottom: 1rem;
  }
  .how-it-works__item .text span {
    left: -2rem;
    margin-top: -6rem;
    font-size: 8rem;
    line-height: 10rem;
  }

  .form-make-your-choice {
    padding: 6rem 4rem;
    border-radius: 0;
  }

  .effective-method {
    padding-bottom: 6rem;
  }
  .effective-method__list-item {
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem 0.5rem 4rem;
  }
  .effective-method__list-item:before {
    width: 1rem;
    height: 1rem;
  }
  .effective-method__promise {
    margin-top: 2rem;
  }
  .effective-method__promise-txt {
    position: relative;
    top: unset;
    left: 0;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  .effective-method__promise-mark {
    display: none;
  }

  .services-price__item-title {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
  .services-price__item-price {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .mistakes {
    background-image: none;
  }
  .mistakes__item {
    margin-bottom: 3rem;
    padding-left: 0;
  }
  .mistakes__item:before {
    display: none;
  }

  .s_video {
    padding-bottom: 6rem;
  }
  .s_video .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .s_faqs .faqs_list {
    margin-left: -30px;
    margin-right: -30px;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .s_faqs .faqs_list a {
    align-items: flex-start;
  }
  .s_faqs .faqs_list a:before {
    background-size: cover;
  }

  .what-we-buy .what-we-buy_ctn {
    padding-right: 0;
  }
  .what-we-buy .what-we-buy-end {
    flex-direction: column;
    align-items: flex-start;
  }
  .what-we-buy .what-we-buy-end__item-1 {
    margin-bottom: 4.4rem;
  }

  .buying-debt-graph__list-item {
    width: 100%;
  }
  .buying-debt-graph__bottom {
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .datepicker {
    width: 300px;
  }
}