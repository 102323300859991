@font-face {
  font-family: 'Sf Pro Display';
  src: local('Sf Pro Display Regular'),
  url('../fonts/SFProDisplay-Regular.woff') format('woff'),
  url('../fonts/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sf Pro Display';
  src: local('Sf Pro Display Ultralight'),
  url('../fonts/SFProDisplay-Ultralight.woff') format('woff'),
  url('../fonts/SFProDisplay-Ultralight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Sf Pro Display';
  src: local('Sf Pro Display Thin'),
  url('../fonts/SFProDisplay-Thin.woff') format('woff'),
  url('../fonts/SFProDisplay-Thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Sf Pro Display';
  src: local('Sf Pro Display Light'),
  url('../fonts/SFProDisplay-Light.woff') format('woff'),
  url('../fonts/SFProDisplay-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Sf Pro Display';
  src: local('Sf Pro Display Medium'),
  url('../fonts/SFProDisplay-Medium.woff') format('woff'),
  url('../fonts/SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Sf Pro Display';
  src: local('Sf Pro Display Semibold'),
  url('../fonts/SFProDisplay-Semibold.woff') format('woff'),
  url('../fonts/SFProDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Sf Pro Display';
  src: local('Sf Pro Display Bold'),
  url('../fonts/SFProDisplay-Bold.woff') format('woff'),
  url('../fonts/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Sf Pro Display';
  src: local('Sf Pro Display Black'),
  url('../fonts/SFProDisplay-Black.woff') format('woff'),
  url('../fonts/SFProDisplay-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Squares';
  src: local('Squares Bold'), local('SquaresBold'),
  url('../fonts/SquaresBold.woff') format('woff'),
  url('../fonts/SquaresBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Adelle Cyrillic';
  src: local('AdelleCyrillic-Bold'), local('AdelleCyrillicBold'),
  url('../fonts/AdelleCyrillic-Bold.woff') format('woff'),
  url('../fonts/AdelleCyrillic-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}