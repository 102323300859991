@import "vars";

// Large devices (desktops, less than 1200px)
@media (max-width: 1259.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 1023.98px) {
  .visible_mob {
    display: flex!important;
  }
  .hidden_mob {
    display: none!important;
  }

  main.active {
    filter: blur(2px);
  }

  .top_menu, .lang_menu {
    display: none;
  }
  .call_btn {
    display: block;
    margin-right: 40px;
    margin-left: auto;
    img {
      display: block;
    }
  }
  .menu_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    //background: url("../img/burger.svg") no-repeat center;
    //transition: background .2s;
    &.active {
      //background-image: url(../img/close.svg);
      .is_opened {
        display: none;
      }
      .is_closed {
        display: block;
      }
    }
    .is_closed {
      display: none;
    }
    img {
      display: block;
    }
  }
  .main_header {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 74px;
    width: 100%;
    background: #1F130A;
    display: flex;
    align-items: center;
    &.opened {}
  }
  .header_inner {
    width: 100%;
    & > a {
      position: relative;
      z-index: 110;
    }
  }
  .menu_mob_wrap {
    padding: 30px 50px 65px;
    display: block;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    background-color: #fff;
  }
  .header_nav {
    //display: block;
    padding: 0;
    padding-top: 74px;
    position: fixed;
    right: -105vw;
    z-index: 100;
    top: 0;
    left: auto;
    width: 60%;
    height: 100vh;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    background-color: transparent;
    text-align: left;
    display: flex;
    justify-content: flex-end;
    transition: right .5s ease-out;
    &.active {
      right: 0;
      transition: right .5s ease;
    }
    li {
      margin-bottom: 0;
      padding-top: 15px;
      padding-bottom: 15px;
      a {
        padding-top: 5px;
        padding-bottom: 5px;
        &:hover {
          color: $text-color;
        }
      }
    }
  }
  .top_menu_mob {
    text-align: left;
    li {
    }
    .sub_li {
      position: relative;
      & > a {
        display: flex;
        align-items: flex-start;
        &:after {
          display: block;
          content: '';
          width: 2.4rem;
          height: 2.4rem;
          background: url(../img/arrow-drop-down-2.svg) no-repeat center;
          -webkit-background-size: 100%;
          background-size: 100%;
        }
      }
      ul {
        padding-top: 20px;
        padding-left: 20px;
        display: none;
      }
      li {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
  .lang_menu_mob {
    text-align: left;
    .unactive {
      display: none;
    }
    .active {
      a {
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        &:after {
          display: block;
          content: '';
          width: 2.4rem;
          height: 2.4rem;
          background: url(../img/arrow-drop-down-2.svg) no-repeat center;
          -webkit-background-size: 100%;
          background-size: 100%;
        }
      }
    }
  }

  html {
    font-size: 10px;
  }
  body {
    font-size: 14px;
    line-height: 25px;
  }

  .container {
    padding-left: 100px;
    padding-right: 100px;
  }
  .home {
    .container {
      padding-left: 100px;
      padding-right: 100px;
    }
  }
  .main_footer_top,
  .main_header {
    padding-left: 30px;
    padding-right: 30px;
  }

  .logo {
    // width: 146px;
    // flex-shrink: 0;
  }

  .s_title {
    font-size: 30px;
    line-height: 36px;
    &:before {
      width: 3rem;
      height: 4.5rem;
      left: -1rem;
    }
  }

  // .credits_lawyer,
  // .credit_type,
  // .debtor_protect,
  // .arrests_cancel,
  // .loan_limitation,
  // .loans_escape,
  // .what_you_get,
  // .our_publications,
  // .s_video,
  // .reasons,
  // .check_data,
  // .s_question_answer,
  // .statements_complaints {
  //   .s_title {
  //     font-size: 22px;
  //     line-height: 26px;
  //     &:before {
  //       width: 30px;
  //       height: 40px;
  //       left: -12px;
  //       top: -7px;
  //     }
  //   }
  // }

  .radio {
    display: flex;
  }
  .radio label {
    width: 21px !important;
    height: 21px;
    &:after {
      top: 3px;
      left: 3px;
      width: 13px;
      height: 13px;
    }
  }

  .popup_default {
    width: 100%;
  }
  .popup_default .body {
    width: 100%;
  }

  body.modal-open {
    height: 100vh;
    overflow: hidden;
  }
  .mfp-content {
    width: 100% !important;
  }
  .mfp-container {
    padding: 40px 20px 10px;
  }
  .mfp-close {
    right: -9px;
    top: -48px;
  }
  .mfp-close:active {
    top: -48px;
  }

  .home_top {
    //overflow: hidden;
    position: relative;
    height: 100vh;
    background: #1F130A;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .top_ctn {
      padding-top: 74px;
      padding-bottom: 74px;
      padding-left: 30px;
      padding-right: 30px;
    }
    h1, .t1 {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
    }
    h2, .t2 {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
    h3, .t3 {
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }
    .buttons {
      margin-top: 50px;
    }
    .bg_trgl {
      display: none;
    }
    .top_bg {
      //position: absolute;
      //top: 0;
      //left: 0;
      //width: 100%;
      //height: 100%;
      //img {
      //  height: 100%;
      //  min-width: 100%;
      //  max-width: none;
      //}
    }
    .mouse {
      display: none;
    }
  }

  .list_styled .num {
    font-size: 80px;
    line-height: 100px;
    margin-top: -48px;
  }
  .list_styled .text, .list_styled p {
    padding-left: 18px;
  }
  .credits_lawyer {
    padding-top: 60px;
    padding-bottom: 100px;
    .s_title {
      margin-bottom: 40px;
    }
    .btns_wrap {
      margin-top: 60px;
    }
  }
  .credits_lawyer_image {
    display: none;
  }
  .credits_lawyer_ctn {
    padding-left: 0;
  }
  .problem_solutions {
    padding-top: 100px;
    padding-bottom: 100px;
    .s_title {
      margin-bottom: 50px;
    }
  }
  .problem_solutions_inner {
    padding-bottom: 10px;
    width: 100%;
    margin: 0;
    flex-wrap: wrap;
  }
  .problem_solutions_item {
    padding: 50px 60px;
    margin-bottom: 40px;
    width: 100%;
    height: 250px;
    margin-left: 0;
    margin-right: 0;
    font-size: 20px;
    line-height: 24px;
    &:last-child {
      //margin-bottom: 0;
    }
    .text span {
      font-size: 120px;
      left: -25px;
    }
  }
  .credit_type {
    padding-top: 60px;
    padding-bottom: 40px;
    .s_title {
      margin-bottom: 30px;
    }
  }
  .credit_type_image {
    display: none;
  }
  .credit_type_ctn {
    padding-top: 0;
    padding-right: 0;
  }
  .credit_type_items {
    .item {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 21px;
      .text {
        white-space: nowrap;
      }
    }
  }
  .what_to_do {
    padding-top: 100px;
    padding-bottom: 100px;
    .s_title {
      margin-bottom: 60px;
    }
    .bg {
      clip-path: polygon(0 0, 100% 3%, 100% 100%, 0% 100%);
    }
  }
  .what_to_do_inner {
    width: 100%;
    margin: 0;
    flex-wrap: wrap;
  }
  .what_to_do_item {
    margin-bottom: 40px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .debtor_protect {
    padding-top: 60px;
    padding-bottom: 50px;
    .s_title {
      margin-bottom: 30px;
    }
  }
  .debtor_protect_image {
    display: none;
  }
  .debtor_protect_ctn {
    padding-left: 0;
  }
  .debtor_protect_items {
    .item {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 21px;
      .num {
        margin-top: -48px;
      }
    }
    .item_text {
      height: 42px;
      display: flex;
      align-items: center;
    }
  }
  .arrests_cancel {
    padding-top: 60px;
    padding-bottom: 40px;
    .s_title {
      margin-bottom: 50px;
    }
  }
  .arrests_cancel_inner {
    width: 100%;
    margin: 0;
  }
  .arrests_cancel_item {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;
    text-align: center;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .bankruptcy {
    padding-top: 60px;
    padding-bottom: 100px;
  }
  .bankruptcy .triangle {
    display: none;
  }
  .loan_limitation {
    padding-top: 60px;
    padding-bottom: 60px;
    .s_title {
      margin-bottom: 30px;
    }
  }
  .loan_limitation_image {
    display: none;
  }
  .loan_limitation_ctn {
    padding-left: 0;
    padding-top: 0;
  }
  .loan_limitation_items .item {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 21px;
    .num {
      margin-top: -48px;
    }
  }
  .registers {
    padding-top: 100px;
    padding-bottom: 100px;
    .bg {
      clip-path: polygon(0 3%, 100% 0, 100% 100%, 0% 100%);
    }
  }
  .registers_item .item_header {
    height: 120px;
    margin-bottom: 30px;
    padding-left: 30px;
    justify-content: center;
  }

  .loans_escape {
    padding-top: 60px;
    padding-bottom: 70px;
    .s_title {
      margin-bottom: 30px;
    }
    .btns_wrap {
      margin-top: 60px;
    }
  }
  .loans_escape_image {
    display: none;
  }
  .loans_escape_ctn {
    padding-left: 0;
  }
  .loans_escape_items {
    .item {
      margin-top: 30px;
      margin-bottom: 30px;
      width: 50%;
      font-size: 18px;
      line-height: 21px;
      font-weight: 400;
      align-items: flex-start;
      .num {
        top: -24px;
        margin-top: 0;
      }
    }
    .item_text {
      height: 42px;
      display: flex;
      align-items: center;
    }
  }

  .what_you_get {
    padding-top: 60px;
    padding-bottom: 70px;
    .s_title {
      margin-bottom: 30px;
    }
  }
  .what_you_get_image {
    display: none;
  }
  .what_you_get_ctn {
    padding-right: 0;
  }
  .what_you_get_items {
    .item {
      margin-top: 30px;
      margin-bottom: 30px;
      width: 50%;
      font-size: 18px;
      line-height: 21px;
      font-weight: 400;
      align-items: flex-start;
      .num {
        margin-left: -5px;
        top: -24px;
        margin-top: 0;
      }
    }
    .item_text {
      margin-bottom: 0;
      height: 42px;
      display: flex;
      align-items: center;
    }
  }

  .reasons {
    padding-top: 60px;
    padding-bottom: 70px;
    .s_title {
      margin-bottom: 30px;
    }
    .btns_wrap {
      margin-top: 60px;
    }
  }
  .reasons_image {
    display: none;
  }
  .reasons_ctn {
    padding-left: 0;
  }
  .reasons_items {
    .item {
      margin-top: 30px;
      margin-bottom: 30px;
      width: 50%;
      font-size: 18px;
      line-height: 21px;
      font-weight: 400;
      align-items: flex-start;
      .num {
        top: -24px;
        margin-top: 0;
      }
    }
    .item_text {
      height: 42px;
      display: flex;
      align-items: center;
    }
    .accent {
      left: 0!important;
    }
  }

  .how_it_work {
    padding-top: 60px;
    padding-bottom: 0;
    .container {
      flex-direction: column;
    }
    .page_form {
      position: relative;
      margin-top: 70px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 60px;
      border-radius: 0;
      align-self: center;
      &:before {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        left: -500px;
        height: 100%;
        width: 5000px;
        //background: rgba(#6F5641, 0.7);
        background: darken(#6F5641, 5);
      }
      form {
        position: relative;
        z-index: 10;
      }
    }
  }
  .how_it_work_items {
    margin-top: 50px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    li {
      padding-left: 18px;
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    span {
      margin-top: -47px;
      font-weight: 700;
      font-size: 80px;
      line-height: 100px;
    }
  }

  .prices {
    padding-top: 70px;
    padding-bottom: 100px;
    .s_title {
      margin-bottom: 50px;
    }
  }
  .prices_inner {
    flex-direction: column;
  }
  .price_list {
    margin-bottom: 65px;
    .price {
      white-space: nowrap;
    }
  }
  .prices_banner {
    width: 100%;
    .t1 {
      margin-bottom: 20px;
    }
  }

  .pic_banner {
    padding-top: 60px;
    padding-bottom: 60px;
    .container {
      display: block;
    }
  }
  .pic_banner_left {
    margin-bottom: 30px;
    .pic_logo {
      margin-bottom: 30px;
      width: 161px;
    }
    .t1 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }
  .pic_banner_right {
    .item {
      margin-bottom: 30px;
      display: block;
      font-size: 22px;
      line-height: 26px;
      &:last-child {
        margin-bottom: 0;
      }
      p {
        padding-right: 0;
      }
      .buttons {
        margin-top: 30px;
      }
    }
  }

  .pic_banner_2 {
    display: block;
  }

  .microcredit_banner {
    padding-top: 70px;
    padding-bottom: 70px;
    .container {
      display: block;
    }
    .trgl-1 {
      display: none;
    }
    .item {
      margin-bottom: 30px;
      width: 100%;
    }
  }

  .statements_complaints {
    position: relative;
    padding-top: 65px;
    padding-bottom: 100px;
    .container {
      display: block;
      position: static;
    }
    .s_title {
      margin-bottom: 100px;
    }
    .btns_wrap {
      flex-direction: column;
      align-items: flex-start;
    }
    .buttons {
      margin-right: 0;
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .image {
      position: absolute;
      width: 246px;
      right: 40px;
      bottom: 30px;
    }
  }

  .s_socials {
    padding-top: 70px;
    padding-bottom: 100px;
    .s_title {
      margin-bottom: 130px;
    }
  }
  .socials {
    width: 290px;
    flex-wrap: wrap;
    justify-content: space-between;
    a {
      display: block;
      width: 45px;
      height: 45px;
      margin-bottom: 40px;
      margin-right: 25px;
      margin-left: 25px;
      &:last-child {
        margin-right: 25px;
      }
      img {
        width: 100%;
      }
    }
  }

  .our_publications {
    padding-top: 75px;
    padding-bottom: 100px;
    .s_title {
      margin-bottom: 65px;
      align-self: flex-start;
    }
  }
  .our_publications_inner {
    padding-bottom: 40px;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .our_publications_item {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 30px;
    .img_wrap {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }

  .s_video {
    padding-top: 100px;
    padding-bottom: 0px;
    .container {
      display: block;
      padding-left: 0;
      padding-right: 0;
    }
    .video_frame {
      width: 100%;
      height: 56vw;
    }
    .buttons {
      margin-top: 40px;
    }
    .s_title {
      margin-bottom: 30px;
    }
    br {
      display: none;
    }
  }
  .s_video_inner {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 45px;
  }

  .s_question_answer {
    padding-top: 75px;
    padding-bottom: 60px;
    .container {
      display: block;
    }
    .left {
      display: none;
      .s_title {
        margin-bottom: 30px;
      }
    }
    .right {
      .s_title {
        margin-bottom: 30px;
      }
    }
  }

  .s_faqs {
    padding-top: 75px;
    padding-bottom: 60px;
    .s_title {
      margin-bottom: 44px;
    }
  }

  .question_answer {
    width: 100%;
    padding-right: 0;
    .question_text {
      font-size: 20px;
      line-height: 24px;
    }
    .item {
      margin-bottom: 30px;
    }
  }
  .comments-area {
    width: 100%;
    padding-right: 0;
  }

  .check_data {
    padding-top: 75px;
    padding-bottom: 100px;
    .s_title {
      margin-bottom: 70px;
    }
    .btns_wrap {
      margin-top: 0;
    }
  }
  .check_data_ctn {
    padding-top: 0;
    padding-right: 0;
  }
  .check_data_image {
    display: none;
  }

  .page_form {
    width: 100%;
  }

  .how_pay_services {
    padding-top: 100px;
    padding-bottom: 60px;
    .s_title {
      display: none;
    }
    .inner {
      flex-direction: column;
      align-items: center;
    }
    .buttons {
      margin-bottom: 40px;
    }
  }

  .page_services .s_video {
    padding-top: 0;
    display: none;
  }

  .services_block {
    padding: 70px 30px 60px;
  }
  .services_block .s_title {
    margin-bottom: 50px;
  }
  .services_list li {
    align-items: flex-start;
  }
  .services_list .price {
    width: 110px;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-end;
  }
  .services_list {
    .pay_btn {
      margin-top: 10px;
      margin-left: 0;
    }
  }

  .google_reviews {
    display: none;
  }

  .page_top {
    height: 85vh;
    .top_ctn {
      height: 100%;
      padding-top: 85px;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 40px;
      br {
        display: none;
      }
    }
    .top_links {
      display: none;
    }
    h1, .t1 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 40px;
      .accent {
        font-size: 40px;
        line-height: 48px;
        display: block;
      }
    }
    h2, .t2 {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }
    .top_ctn_inner {
      .buttons {
        margin-top: 120px;
      }
    }
  }

  .page_top_ban {
    // height: auto;
    .top_ctn {
      padding-top: 120px;
      padding-bottom: 100px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .page_top_ban .top_bg {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      height: auto;
      flex-shrink: 0;
    }
  }
  .page_top_ban .top_ctn_inner {
    text-align: left;
  }

  .page_contacts_inner {
    padding-top: 75px;
    padding-bottom: 100px;
    .container {
      display: flex;
    }
    .left {
      padding-bottom: 50px;
      .s_title {
        margin-bottom: 50px;
      }
    }
    .right {
      .s_title {
        margin-bottom: 50px;
      }
    }
    .address {
      margin-bottom: 30px;
    }
    .email {
      margin-bottom: 30px;
    }
    .phones {
      margin-bottom: 30px;
    }
    .contacts_item {
      padding-left: 4rem;
    }
    .socials {
      padding-top: 20px;
      width: 290px;
      flex-wrap: wrap;
      justify-content: space-between;
      a {
        display: block;
        width: 45px;
        height: 45px;
        margin-bottom: 40px;
        margin-right: 50px;
        margin-left: 0;
        &:last-child {
          margin-right: 50px;
        }
        img {
          width: 100%;
        }
      }
    }
  }

  .ask_form {
    width: 100%;
  }

  .page_article_inner {
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
  }
  .article_wrap {
    padding-bottom: 80px;
  }
  .article_wrap .quotted {
    padding: 30px 40px;
  }
  .article_wrap .quote {
    width: 40px;
    padding: 10px;
  }
  .red_banner .item_ctn {
    padding: 30px 40px;
  }

  .main_footer_top {
    padding-top: 60px;
    padding-bottom: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .footer_socials {
    margin-top: 60px;
    padding-bottom: 40px;
    width: 290px;
    flex-wrap: wrap;
    justify-content: space-between;
    a {
      display: block;
      width: 45px;
      height: 45px;
      margin-bottom: 40px;
      margin-right: 25px;
      margin-left: 25px;
      &:last-child {
        margin-right: 25px;
      }
      img {
        width: 100%;
      }
    }
  }
  .main_footer {
    .logo {
      margin-left: auto;
      margin-right: auto;
      width: 260px;
      img {
        // width: 100%;
      }
    }
    .contacts {
      margin-bottom: 100px;
      text-align: center;
    }
    .address {
      margin-bottom: 25px;
    }
    .email {
      margin-bottom: 25px;
    }
    .phones {
      flex-direction: column;
      align-items: center;
      a {
        margin-right: 0;
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .links {
      text-align: center;
    }
  }
  .map, .map_2 {
    display: none;
  }

// ====================(max-width: 1023.98px)==========================================

  .page_top_w-adv .top_ctn, .page-top-buy-debt .top_ctn {
    padding: 85px 100px 40px;
  }

  .page-top-advantages {
    &__item {
      font-size: 1.8rem;
      line-height: 2.1rem;
      margin-bottom: 4rem;
      &:before {
          height: 4.5rem;
          left: -1.5rem;
          top: -1.5rem;
          width: 3rem;
      }
    }
  }


  // =================== Main Page =========(max-width: 1023.98px)================

  .page_top_w-adv {
    .title-1 {
      font-size: 4rem;
      line-height: 4.8rem;
    }
    .title-2 {
      font-size: 4.8rem;
      line-height: 5.7rem;
      & .larger {
        font-size: inherit;
        line-height: inherit;
      }
    }
    .top_ctn_inner>p {
      display: none;
    }
    a.buttons {
      margin-top: 5rem!important;
    }
  }


  .what-if {
    padding-top: 6rem;
    padding-bottom: 6rem;
    &_image {
      display: none;
    }
    &_ctn {
      padding-left: 0;
    }
    .container {
      padding-left: 100px;
      padding-right: 100px;
    }
    .sub_title {
      margin-bottom: 3rem;
      font-size: 2rem;
      line-height: 3rem;
    }
    .what-if_items {
      .item {
        font-size: 1.8rem;
        line-height: 2.1rem;
        margin-top: 0;
        margin-bottom: 3rem;
        width: 60%;
      }
    }
    .what-if-end {
      flex-direction: column;
      align-items: flex-start;
      &__item-1 {
        margin-right: 5rem;
        margin-bottom: 5rem;
      }
    }
  }

  .how-it-works {
    padding-top: 5rem;
    padding-bottom: 5rem;
    &__left {
      width: 45%;
    }
    &__right {
      width: 55%;
    }
    &__item {
      font-size: 1.8rem;
      line-height: 2.5rem;
      .text span {
        // font-size: 8rem;
        // line-height: 10rem;
      }
    }
  }

  .form-make-your-choice {
    padding: 6rem 2rem;
  }

  .effective-method {
    padding-top: 6rem;
    padding-bottom: 2rem;
    .sub_title {
      margin-bottom: 3rem;
      font-size: 2rem;
      line-height: 3rem;
    }
    &__list-title {
      margin-bottom: 3rem;
      font-size: 1.8rem;
      line-height: 2.1rem;
    }
    &__list-item {
      font-size: 1.8rem;
      line-height: 2.1rem;
    }
  }


  .get-faster {
    padding-top: 6rem;
    padding-bottom: 6rem;
    .sub_title {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }


  .services-price {
    padding-top: 6rem;
    padding-bottom: 6rem;
    .s_title {
      margin-bottom: 5rem;
    }
    &__left {
      width: 100%;
      padding-bottom: 5rem;
    }
    &__right {
      width: 100%;
    }
  }


  .mistakes {
    padding-top: 6rem;
    padding-bottom: 6rem;
    .container {
      padding-left: 100px;
      padding-right: 100px;
    }
    .s_title {
      margin-bottom: 5rem;
    }
    &__item {
      font-size: 2rem;
      line-height: 2.4rem;
      &-title {
        margin-bottom: 1.5rem;
      }
    }
  }


  // =================== Buying debt Page =========(max-width: 1023.98px)================

  .page-top-buy-debt {
    h1.page-top-buy-debt__title-1 {
        font-size: 3.7rem;
        line-height: 4.4rem;
        margin-bottom: 1rem;
    }
    h2.page-top-buy-debt__title-2 {
      font-size: 3.2rem;
      line-height: 3.8rem;
      & .smaller {
        font-size: 1.6rem;
        line-height: 1.9rem;
      }
    }
    &__title-2-right {
      font-size: 7rem;
      line-height: 6.4rem;
    }
    .page-top-advantages {
      margin-top: 5rem;
      max-width: 90%;
    }
    .top_ctn_inner>p {
      display: none;
    }
    a.buttons {
      margin-top: 5rem!important;
    }
  }


  .what-we-buy {
    padding-top: 6rem;
    padding-bottom: 6rem;
    &_image {
      display: none;
    }
    &_ctn {
      padding-left: 0;
    }
    .container {
      padding-left: 100px;
      padding-right: 100px;
    }
    .sub_title {
      margin-bottom: 3rem;
      font-size: 2rem;
      line-height: 3rem;
    }
    .what-we-buy_items {
      margin-top: 5rem;
      .item {
        font-size: 1.8rem;
        line-height: 2.1rem;
        margin-top: 0;
        margin-bottom: 3rem;
        width: 100%;
      }
    }
    .what-we-buy-end {
      &__item-1 {
        font-size: 2.5rem;
        line-height: 3rem;
        margin-right: 7rem;
      }
    }
  }


  .interested {
    padding-top: 6rem;
    padding-bottom: 6rem;
    .sub_title {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }


  .main-advantages {
    padding-top: 6rem;
    padding-bottom: 6rem;
    .main-advantages_items {
      .item {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }

  .page_buy-debt .s_video {
    padding-top: 0;
    padding-bottom: 6rem;
  }


  .buying-debt-graph {
    padding-top: 6rem;
    padding-bottom: 6rem;
    .buying-debt-graph_wrap {
      align-items: flex-start;
    }
    &__list-cont {
      background: none;
      margin-top: 5rem;
      margin-left: 0;
      width: unset;
      height: unset;
    }
    &__list-item {
      position: relative;
      left: unset!important;
      top: unset!important;
      width: 50%;
      margin-bottom: 3rem;
    }
    &__bottom {
      position: relative;
      bottom: unset;
      width: 70%;
    }
  }


}


@media (max-width: 900px) {

  .logo__txt {
    font-size: 10px;
    padding-left: 10px;
  }

  .call_btn {
    margin-right: 20px;
  }

  .header_nav {
    width: 80%;
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .home {
    .container {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .page_top {
    .top_ctn {
      text-align: left;
    }
  }

  .credits_lawyer {
    padding-bottom: 70px;
    .btns_wrap {
      flex-direction: column;
      align-items: flex-start;
    }
    .buttons {
      margin-bottom: 30px;
      margin-right: 0;
    }
  }
  .credit_type_items {
    .item {
      width: 100%;
    }
  }
  .debtor_protect_items {
    .item {
      width: 100%;
    }
  }
  .loan_limitation_items {
    .item {
      width: 100%;
    }
  }
  .registers_inner {
    width: 100%;
    margin: 0;
    flex-wrap: wrap;
  }
  .registers_item {
    margin-bottom: 40px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    &:last-child {
      margin-bottom: 0;
    }
    .item_header {
      height: auto;
      padding-left: 0;
    }
  }
  .what_to_do_item {
    font-size: 20px;
    line-height: 24px;
  }
  .arrests_cancel_item {
    font-size: 20px;
    line-height: 24px;
    .item_ctn {
      padding: 30px 20px;
    }
    .btns_wrap {
      margin-top: 40px;
    }
    .buttons {
      height: 34px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 14px;
    }
  }

  .loans_escape_items {
    .item {
      width: 100%;
    }
  }
  .what_you_get_items {
    .item {
      width: 100%;
    }
  }
  .reasons {
    .item {
      width: 100%;
    }
  }

  .prices_banner {
    text-align: left;
    .item_ctn {
      padding-left: 30px;
      padding-right: 30px;
      display: block;
    }
  }

  .statements_complaints {
    .image {
      right: -170px;
    }
  }

  .s_video_inner {
    padding-left: 30px;
    padding-right: 30px;
  }

  .how_pay_services {
    .buttons {
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
    }
  }
  .services_wrap {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .services_block {
    padding-left: 0;
    padding-right: 0;
    & > p {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .services_list {
    li {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .page_article_inner {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page_contacts_inner .container {
    display: block;
  }

  .page-top-buy-debt .top_ctn, .page_top_w-adv .top_ctn {
    padding: 120px 30px 40px;
  }

  .container {
    padding-left: 30px!important;
    padding-right: 30px!important;
  }

  .page_top {
    height: unset;
  }

  .s_title {
    font-size: 2.2rem;
    line-height: 2.6rem;
    &:before {
      height: 4rem;
      left: -1rem;
      width: 3.9rem;
    }
  }

  // ================ Main PAGE ========max-width: 900px==================

  .page-top-advantages {
    &__item {
      font-size: 1.8rem;
      line-height: 2.1rem;
      margin-bottom: 4rem;
      max-width: 100%;
      margin-right: 0;
      &:before {
          height: 3.5rem;
          left: -1rem;
          top: -0.5rem;
          width: 3rem;
      }
    }
  }


  .page_top_w-adv {
    .title-1 {
      font-size: 3rem;
      line-height: 3.6rem;
    }
    .title-2 {
      font-size: 3.8rem;
      line-height: 4.5rem;
      & .larger {
        font-size: inherit;
        line-height: inherit;
      }
    }
    .top_ctn_inner>p {
      display: none;
    }
    a.buttons {
      margin-top: 3rem!important;
    }
  }


  .what-if {
    padding-top: 6rem;
    padding-bottom: 6rem;
    &_image {
      display: none;
    }
    &_ctn {
      padding-left: 0;
    }
    .container {
      padding-left: 100px;
      padding-right: 100px;
    }
    .sub_title {
      margin-bottom: 3rem;
      font-size: 2rem;
      line-height: 3rem;
    }
    .what-if_items {
      .item {
        font-size: 1.8rem;
        line-height: 2.1rem;
        margin-top: 0;
        margin-bottom: 3rem;
        width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .what-if-end {
      flex-direction: column;
      align-items: flex-start;
      &__item-1 {
        margin-right: 5rem;
        margin-bottom: 5rem;
      }
    }
  }

  .how-it-works {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background: none;
    padding-top: 0;
    padding-bottom: 0;
    .container {
      flex-direction: column;
      padding-left: 0!important;
      padding-right: 0!important;
    }
    .s_title {
      font-size: 3rem;
      line-height: 3.6rem;
    }
    &__left {
      width: 100%!important;
      padding: 5rem 30px 8rem 30px;
      background: url(../img/how-it-works-bg.png) 30% 50%;
      background-size: cover;
    }
    &__right {
      width: 100%!important;
    }
    .sub_title {
      margin-bottom: 5rem;
    }
    &__item {
      font-size: 1.8rem;
      line-height: 2.5rem;
      padding-right: 0;
      margin-bottom: 1rem;
      .text span {
        left: -2rem;
        margin-top: -6rem;
        font-size: 8rem;
        line-height: 10rem;
      }
    }
  }

  .form-make-your-choice {
    padding: 6rem 4rem;
    border-radius: 0;
  }

  .effective-method {
    padding-bottom: 6rem;
    &__list-item {
      width: 100%;
      margin-bottom: 1rem;
      padding: 0.5rem 1rem 0.5rem 4rem;
      &:before {
        width: 1rem;
        height: 1rem;
      }
    }
    &__promise {
      margin-top: 2rem;
    }
    &__promise-txt {
      position: relative;
      top: unset;
      left: 0;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
    &__promise-mark {
      display: none;
    }
  }


  .services-price {
    &__item-title {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
    &__item-price {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }


  .mistakes {
    background-image: none;
    &__item {
      margin-bottom: 3rem;
      padding-left: 0;
      &:before {
        display: none;
      }
    }
  }

  .s_video {
    padding-bottom: 6rem;
    .container {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }


  .s_faqs {
    .faqs_list {
      margin-left: -30px;
      margin-right: -30px;
      font-size: 2rem;
      line-height: 2.4rem;
      a {
        align-items: flex-start;
      }
      a:before {
        background-size: cover;
      }
    }
  }


  .what-we-buy {
    .what-we-buy_ctn {
      padding-right: 0;
    }
    .what-we-buy-end {
      flex-direction: column;
      align-items: flex-start;
      &__item-1 {
        margin-bottom: 4.4rem;
      }
    }
  }


  .buying-debt-graph {
    &__list-item {
      width: 100%;
    }
    &__bottom {
      width: 100%;
    }
  }

}

@media (max-width: 640.98px) {
}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .datepicker {
    width: 300px;
  }
}

@media (max-width: 479.98px) {
}

@media (max-width: 374.98px) {
}


