@import "vars";

@media (max-width: 1900px) {
  html {
    font-size: 9px;
  }
}

@media (max-width: 1679.98px) {
  html {
    font-size: 8.5px;
  }
  .home {
    .container {
      position: relative;
      padding-left: 32rem;
      padding-right: 32rem;
    }
  }
  .home_top .top_ctn {
    padding-top: 33rem;
  }
}

@media (max-width: 1599.98px) {
  html {
    font-size: 8px;
  }
  body {
    font-size: 14px;
  }
  .top_menu, .lang_menu {
    font-size: 14px;
    line-height: 17px;
  }
  .container {
    position: relative;
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .home {
    .container {
      position: relative;
      padding-left: 26rem;
      padding-right: 26rem;
    }
  }
  .what-if .container {
    padding-left: 20rem;
    padding-right: 8rem;
  }
  .main_footer_top {
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .question_answer {
    width: 70rem;
  }
  .comments-area {
    width: 70rem;
  }
}

@media (max-width: 1439.98px) {
  html {
    font-size: 7.5px;
  }
  .home_top {
    .top_ctn {
      padding-left: 0;
      padding-right: 0;
    }
    .bg_trgl {
      width: 70rem;
      margin-left: -35rem;
    }
    .buttons {
      margin-top: 10rem;
    }
    .mouse {
      bottom: 3rem;
    }
  }
}

@media (max-width: 1365.98px) {
  html {
    font-size: 7px;
  }
  .top_menu, .lang_menu {
    font-size: 12px;
  }
  .container {
    position: relative;
    padding-left: 13rem;
    padding-right: 13rem;
  }
  .home {
    .container {
      position: relative;
      padding-left: 13rem;
      padding-right: 13rem;
    }
  }
  .what-if .container {
    padding-left: 13rem;
    padding-right: 8rem;
  }
  .mistakes .container {
    padding-left: 26rem;
    padding-right: 26rem;
  }
  .main_footer_top,
  .main_header {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .page_num {
    display: none;
  }
  .home_top {
    .top_ctn {
      padding-top: 25rem;
    }
    .bg_trgl {
      width: 60rem;
      margin-left: -30rem;
    }
    .buttons {
      margin-top: 7rem;
    }
    .mouse {
      bottom: 3rem;
    }
  }
  .pic_banner_left {
    max-width: 35rem;
    .text_arrow {
      display: none;
    }
  }
  .buying-debt-graph__list-cont {
    margin-left: -26rem;
  }
  .page-top-advantages {
    max-width: 90%;
  }
  .what-if-end__item-1 {
    margin-right: 7rem;
  }
}
@media (max-width: 1259.98px) {
  html {
    //font-size: 8px;
  }
}
@media (min-width: 992px) and (max-width: 1259.98px) {

}
@media (min-width: 992px) and (max-width: 1280px) and (orientation: portrait) {
  html {
    font-size: 10px;
  }
}
