@import "fonts";
@import "vars";

html, body {
  height: 100%;
}

html{
  box-sizing:border-box;
  -ms-overflow-style:scrollbar
}

body {
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Sf Pro Display', sans-serif;
  font-size: 1.6rem;
  line-height: 2.3rem;
  font-weight: 400;
  color: $text-color;
  background: transparent;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  scrollbar-width: none;
  /* width */
  &::-webkit-scrollbar {
    width: 0;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #a1a1a1;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
}

*,::after,::before{
  box-sizing:inherit
}
.container{
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-right:auto;
  margin-left:auto
}

.wrapper {
  min-width: 320px;
  //height: 100vh;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //overflow: hidden;
}
.sr-only {
  height: 0;
  display: none;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0; }
ul li {
  list-style: none; }
ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 17px;
}
a {
  color: inherit;
  text-decoration: none;
  transition: color .2s;
  &:hover, &:focus {
    text-decoration: none;
    color: $accent-color;
    outline: none;
  }
}
p {
  margin-bottom: 0;
  margin-top: 0;
}
video {
  display: flex;
  max-width: 100%;
}
input {
  padding: 0;
  outline: none;
  border: none;
  max-width: 100%;
}
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
img {
  max-width: 100%;
  height: auto;
}
button {
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
  outline: none!important;
  transition: all .2s;
  i, img {
    transition: all .2s;
  }
}
.bold {
  font-weight: 700;
}
.sbold {
  font-weight: 500;
}
.thin {
  font-weight: 300;
}
.accent {
  color: $accent-color;
}
.red {
  color: $accent-color;
}
.uppercase {
  text-transform: uppercase;
}

.huge {
  font-style: normal;
  font-weight: 700;
  font-size: 8rem;
  line-height: 9.5rem;
}

.checkbox {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.checkbox input[type="checkbox"] {
  position: absolute;
  left: -9999px;
}
.checkbox label {
  padding: 0;
  position: relative;
  display: inline-block !important;
  margin-top: 0;
  margin-right: 1rem;
  margin-bottom: 0;
  width: 2.6rem !important;
  height: 2.6rem;
  vertical-align: top;
  outline: none;
  cursor: pointer;
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 4px;
  flex-shrink: 0;
  &:after {
    position: absolute;
    display: block;
    content: '';
    top: 0.3rem;
    left: 0.3rem;
    width: 1.8rem;
    height: 1.8rem;
    background: transparent;
    border-radius: 2px;
  }
}
.checkbox input[type="checkbox"]:checked + label {
  background: #fff;
}
.checkbox input[type="checkbox"]:checked + label:after {
  background: #C7352B;
}
.checkbox label + span {
  font-size: 2rem;
  line-height: 1.2;
}
.checkbox a {
  font-size: 2rem;
  line-height: 1.2;
  text-decoration: underline;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.radio {
  margin-top: 0;
  margin-bottom: 0;
}
.radio input[type="radio"] {
  position: absolute;
  left: -9999px;
}
.radio label {
  padding: 0;
  position: relative;
  display: inline-block !important;
  margin-top: 0;
  margin-right: 1rem;
  margin-bottom: 0;
  width: 2.6rem !important;
  height: 2.6rem;
  vertical-align: top;
  outline: none;
  cursor: pointer;
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 4px;
  flex-shrink: 0;
  &:after {
    position: absolute;
    display: block;
    content: '';
    top: 0.3rem;
    left: 0.3rem;
    width: 1.8rem;
    height: 1.8rem;
    background: transparent;
    border-radius: 2px;
  }
}
.radio input[type="radio"]:checked + label {
  background: #fff;
}
.radio input[type="radio"]:checked + label:after {
  background: #C7352B;
}
.radio label + span {
  font-size: 2rem;
  line-height: 1.2;
}
.radio a {
  font-size: 2rem;
  line-height: 1.2;
  text-decoration: underline;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.selection {
  position: relative;
  color: #000;
  &.active {
    ul {
      display: block;
    }
  }
  .selected {
    padding: 7px 2rem;
    width: 100%;
    height: 4.5rem;
    font-size: 2rem;
    line-height: 143.2%;
    border: 1px solid rgba(0,0,0,.05);
    border-radius: 10em;
    &:hover {
      cursor: pointer;
    }
    &::placeholder {
      color: rgba(#000, .6);
    }
  }
  .icon {
    position: absolute;
    top: 0;
    right: 0.5rem;
    width: 4rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }
  ul {
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    margin-top: 0;
    width: 100%;
    border: 1px solid rgba(0,0,0,.05);
    border-top: none;
    display: none;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0,0,0,.15);
    li {
      border-bottom: 1px solid rgba(0,0,0,.05);
      &.active {
        a {
          background-color: rgba(0,0,0,.05);
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
    a {
      display: block;
      padding: 7px 2rem;
      width: 100%;
      height: 4.5rem;
      font-size: 2rem;
      line-height: 143.2%;
      &:hover {
        color: #000;
        background-color: rgba(0,0,0,.05);
      }
    }
  }
}


.buttons_wrap {
  display: flex;
  align-items: flex-start;
}
.buttons {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  height: 3.8rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 400;
  border-radius: 10em;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background: #C7352B;
  color: #fff;
}
.btn-1 {
  position: relative;
  height: 3.8rem;
  top: -1px;
  transition: all .2s;
  box-shadow: 0px 4px 0px #75160F;
  &:active {
    top: 1px;
    color: #fff;
    background: darken(#C7352B, 2);
    box-shadow: 0px 0px 0px #75160F;
    transition: all .2s;
  }
  &:hover {
    color: #fff;
    //background: darken(#C7352B, 5);
    transition: all .2s;
    //box-shadow: 0px 0px 0px #75160F;
    //transition: box-shadow .2s;
  }
  &:focus {
    color: #fff;
  }
}
.btn-2 {
  border: 1px solid #cecbc9;
  background: #fff;
  color: $text-color;
  transition: all .2s;
  &:hover, &:active {
    color: $text-color;
    background: #cecbc9;
  }
  &:focus {
    color: $text-color;
  }
}
.btn-3 {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  transition: all .2s;
  &:focus {
    color: #fff;
    background: transparent;
  }
  &:hover, &:active {
    color: $text-color;
    background: #fff;
  }
}
.btn-4 {
  border: 1px solid #D04B47;
  background: #fff;
  color: #D04B47;
  transition: all .2s;
  &:focus {
    background: #fff;
    color: #D04B47;
  }
  &:hover, &:active {
    color: #fff;
    background: #D04B47;
  }
}
.btn-5 {
  border: 1px solid #BC9E85;
  background: transparent;
  color: #BC9E85;
  font-size: 2rem;
  line-height: 2.4rem;
  transition: all .2s;
  &:focus {
    color: #BC9E85;
  }
  &:hover, &:active {
    color: #fff;
    background: #6F5641;
    border-color: #6F5641;
  }
}

@media (max-width: 991.98px) {
}